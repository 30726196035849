<template>
  <div class="layout-header-section d-lg-flex d-block justify-content-between">
    <div class="header-label col-lg-4 col-12">List Karyakartas</div>
    <div class="
        col-lg-8 col-12
        text-end
        d-flex
        justify-content-end
        custom-flex-cloumn-mob
      ">
      <div class="input-group input-group-sm custom-form-group mb-0 me-2"
        v-bind:style="[searchnamebtnactive ? { width: '328px' } : { width: '275px' }]">
        <input type="text" v-model="searchfullname" class="form-control text-capitalize"
          placeholder="Search By First Or Last Name" aria-label="Search" aria-describedby="basic-addon1"
          autocomplete="off" maxlength="30" v-on:keyup.enter="searchFnameVoter(searchfullname)" @keypress="isLetterWithSpace($event)" :disabled="hidesearchname"
          style="padding: 6px 15px;" />
        <button type="button" class="header-btn-outer btn btn-primary" :disabled="searchfullname.length == ''"
          @click="searchFnameVoter(searchfullname)" :style="{ width: '50px' }">
          <span v-if="!searchnameloader"><i class="pi pi-search" aria-hidden="true"></i></span>
          <div class="spinner-border text-light custom-spinner-loader-btn" role="status" v-if="searchnameloader">
            <span class="visually-hidden">Loading...</span>
          </div>
        </button>
        <button type="button" class="header-btn-outer btn btn-primary ms-1" @click="searchNameVoterStop()"
          :style="{ width: '50px' }" v-show="searchnamebtnactive"><i class="pi pi-times" aria-hidden="true"></i>
        </button>
      </div>
      <div class="input-group input-group-sm custom-form-group mb-0"
        v-bind:style="[searchmobbtnactive ? { width: '275px' } : { width: '220px' }]">
        <input type="text" v-model="searchmobile" class="form-control" id="searchmobInput"
          placeholder="Search By Mobile No." :disabled="hidesearchmob" aria-label="Search" aria-describedby="basic-addon1"
          autocomplete="off" maxlength="10" v-on:keyup.enter="searchMobVoter(searchmobile)" @paste="onPasteMobile" @keypress="mobileSearchFormat" style="padding: 6px 15px;" />
        <button type="button" class="header-btn-outer btn btn-primary" :disabled="searchmobile.length <= 3"
          @click="searchMobVoter(searchmobile)" :style="{ width: '50px' }">
          <span v-if="!searchmobloader"><i class="pi pi-search" aria-hidden="true"></i></span>
          <div class="spinner-border text-light custom-spinner-loader-btn" role="status" v-if="searchmobloader">
            <span class="visually-hidden">Loading...</span>
          </div>
        </button>
        <button type="button" class="header-btn-outer btn btn-primary ms-1" @click="searchMobVoterStop()"
          :style="{ width: '50px' }" v-show="searchmobbtnactive"><i class="pi pi-times" aria-hidden="true"></i>
        </button>
      </div>
      <button type="button" @click="voterFilterModalOpen()" class="header-filter-btn-outer btn btn-light" title="Filter">
        <span class="custom-filter-apply-alert"></span>
        <i class="pi pi-filter-fill custom-text-primary" aria-hidden="true"></i>
      </button>
    </div>
  </div>
  <ul class="nav nav-tabs" id="myTab" role="tablist">
    <li class="nav-item" role="presentation">
      <button class="nav-link active" id="voter-all-details-tab" data-bs-toggle="tab" data-bs-target="#voteralldetail"
        type="button" role="tab" aria-controls="vehicle-details" aria-selected="true">
        Karyakartas Details
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button class="nav-link" id="voter-report-tab" data-bs-toggle="tab" data-bs-target="#voterreport" type="button"
        role="tab" aria-controls="customer-details" aria-selected="false" @click="redirecttoreport">
        Reports
      </button>
    </li>
  </ul>
  <div class="tab-content" id="myTabContent">
    <div class="tab-pane fade" id="voterreport" role="tabpanel" aria-labelledby="voter-report-tab"></div>
    <div class="tab-pane fade show active" id="voteralldetail" role="tabpanel" aria-labelledby="voter-all-details-tab">
      <div class="company-section-outer">
        <div class="layout-content-section custom-bg-datatable-transparent">
          <div class="custom-ultima-datatable custom-voter-datable custom-bg-datatable-transparent custom-without-checkbox-datatable p-3" style="height: calc(100vh - 180px)">
            <DataTable :value="karyakartaList" :scrollable="true" columnResizeMode="fit" scrollHeight="flex"
              :paginator="true" :rows="30" :totalRecords="totalRecords" @page="changePage($event)"
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
              :lazy="true" dataKey="ak1"  :loading="loading" class="p-datatable-sm">
              <!-- <template #paginatorstart>
                <div>
                  <span class="voter-only-circle-pagination me-1"></span>
                  <span class="voter-circle-pagination-text">Voter</span>
                  <span class="voter-user-circle-pagination me-1"></span>
                  <span class="voter-circle-pagination-text">User</span>
                  <span class="voter-karyakartas-circle-pagination me-1"></span>
                  <span class="voter-circle-pagination-text">Karyakartas</span>
                </div>
              </template> -->
              <!-- <template #paginatorend></template> -->
              <template v-if="!loading" #empty>No records found.</template>
              <template #loading><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i></template>
              <Column  headerStyle="width: 99%" bodyStyle="width: 99%">
                <template #body="{ data }">
                  <div class="company-detail-box-outer mb-3">
                    <div class="row g-2">
                      <div class="
                          col-lg-2 col-md-2 col-12
                          voter-profile-col
                          d-flex
                          align-items-center
                        ">
                        <div class="customer-label-group">
                          <div class="d-flex align-items-center">
                            <div :class="data.ak29 == 3
                              ? 'border-voter-user' : data.ak29 == 2 ? 'border-voter-karyakartas'
                                : 'border-voter-only'
                              ">
                              <img v-if="(data.ak90 == null || data.ak90 == '') && (data.ak33 == null || data.ak33 == '')"
                                src="/assets/images/profile.svg" class="
                                      rounded
                                      bg-light
                                      me-auto
                                      d-block
                                    " alt="" style="width: 72px; height: 72px;" />
                              <img v-if="data.ak90 != null && data.ak90 != ''"
                                :src="'https://storage.googleapis.com/' + this.voterstorageimgpath + '/voterphoto/' + data.ak90"
                                class="
                                      rounded
                                      bg-light
                                      me-auto
                                      d-block
                                      voter-photo-img
                                    " alt="" />
                              <img
                                v-if="(data.ak33 != null && data.ak33 != '') && (data.ak90 == null || data.ak90 == 'NULL' || data.ak90 == '')"
                                :src="'https://storage.googleapis.com/' + this.voterstorageimgpath + '/aadharphoto/' + data.ak33"
                                class="
                                      rounded
                                      bg-light
                                      me-auto
                                      d-block
                                      voter-photo-img
                                    " alt="" />
                              <img v-if="data.ak29 == 1" src="/assets/images/icon/voters-tab-icon.svg" alt=""
                                title="Voter" width="16" />
                              <img v-if="data.ak29 == 2" src="/assets/images/icon/users-tab-icon.svg" alt=""
                                title="Karyakartas" width="16" />
                              <img v-if="data.ak29 == 3" src="/assets/images/icon/customers-tab-icon.svg" alt=""
                                title="User" width="16" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-2 col-md-2 col-12 voter-name-col">
                        <div class="row">
                          <div class="col-lg-12 col-md-12 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Name</label>
                              <div class="from-label-value text-capitalize">
                                {{ data.ak5 ? data.ak5 : "N/A" }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-12 col-md-12 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Party Related Designation</label>
                              <div class="from-label-value text-capitalize">
                                {{ data.ak111 ? data.ak111 : "N/A" }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-2 col-md-2 col-12">
                        <div class="row">
                          <div class="col-lg-12 col-md-12 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label"><span style="width: 80px; display: inline-block;">Date of
                                  Birth</span><span class="mx-1 vertical-pipe-color">|</span><span>Age</span></label>
                              <div class="from-label-value">
                                <span style="width: 80px; display: inline-block;">{{ format_date(data.ak18)
                                }}</span><span class="mx-1 vertical-pipe-color">|</span><span>{{ data.ak19 ? data.ak19 : "N/A" }}</span>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-12 col-md-12 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Email</label>
                              <div class="from-label-value"
                                v-if="this.allowshowemailvoterkaryakartasfk == 0 && (data.ak23 != '') && (data.ak23 != null)">
                                {{ maskedEmail(data.ak23) }}
                              </div>
                              <div class="from-label-value"
                                v-if="this.allowshowemailvoterkaryakartasfk == 1 && (data.ak23 != '' && data.ak23 != null)">
                                {{ data.ak23 }}
                              </div>
                              <div class="from-label-value" v-if="data.ak23 == '' || data.ak23 == null">
                                N/A
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3 col-md-3 col-12">
                        <div class="row">
                          <div class="col-lg-12 col-md-12 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label"><span style="width: 75px; display: inline-block;">Part
                                  Number</span><span class="mx-1 vertical-pipe-color">|</span><span>Pincode</span></label>
                              <div class="from-label-value">
                                <span style="width: 75px; display: inline-block;">{{ data.ak85 ? data.ak85 : "N/A"
                                }}</span><span class="mx-1 vertical-pipe-color">|</span><span v-if="data.ak58 != null">{{ data.ak58
}}</span>
                                <span v-if="data.ak97 != null && data.ak58 == null">{{ data.ak97 }}</span>
                                <span v-if="data.ak97 == null && data.ak58 == null">N/A</span>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-12 col-md-12 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Reg. Date & Time</label>
                              <div class="from-label-value">
                                {{ format_timestamp(data.z501) }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3 col-md-3 col-12">
                        <div class="row">
                          <div class="col-lg-12 col-md-12 col-12">
                            <div class="
                                                              customer-label-group
                                                              d-flex
                                                              justify-content-between mb-2
                                                            ">
                              <div>
                                <label class="form-label">Mobile</label>
                                <div class="from-label-value" v-if="this.allowshowmobilekaryakartasfk == 0">
                                  +91-{{ data.ak24 ? maskedNumber(data.ak24) : "N/A" }}
                                </div>
                                <div class="from-label-value" v-if="this.allowshowmobilekaryakartasfk == 1">
                                  +91-{{ data.ak24 ? data.ak24 : "N/A" }}
                                </div>
                              </div>
                              <div class="d-flex align-items-end">
                                <span v-if="this.virtualNumberCount != 0 && this.allowtouseroutgoingcall != 0">
                                  <!-- Signal oprator condition start -->
                                  <button v-if="this.virtualNumberCount == 1" class="btn custom-outline-call-btn me-2"
                                    title="Call" :disabled="callstatus == 1"
                                    @click="CallTrigerOperatorNumber(data.ak1, data.ak24, this.virtualNumberList[0]['value'])">
                                    <i class="pi pi-phone call-btn-color"></i></button>
                                  <!-- Signal oprator condition end -->
                                  <!-- more oprator condition start -->
                                  <button class="btn custom-outline-call-btn me-2"
                                    @click="toggleOperatorCallList(data.ak1, $event)" :disabled="callstatus == 1"
                                    title="Call" v-if="this.virtualNumberCount != 1">
                                    <i class="pi pi-phone call-btn-color"></i></button>
                                  <OverlayPanel :ref="`${data.ak1}`" appendTo="body" id="overlay_panel"
                                    class="custom-overlay-call-btn">
                                    <div class="overlay-reg-label" v-for="opt  in virtualNumberList" :key="opt">
                                      <div>
                                        <button class="btn call-trigger-list-btn"
                                          @click="CallTrigerOperatorNumber(data.ak1, data.ak24, opt.value, $event)">
                                          <i class="pi pi-phone overlay-icon me-2" style="font-size: 11px;"></i> {{
                                            opt.label }}
                                        </button>
                                      </div>
                                      <Divider class="my-1" />
                                    </div>
                                  </OverlayPanel>
                                </span>
                                <!-- more oprator condition end -->
                                <!-- <button class="btn custom-outline-whatsapp-btn me-2" title="WhatsApp"
                                  @click="whatsappHistoryModalOpen(data)">
                                  <i class="pi pi-whatsapp whatsapp-color"></i>
                                </button> -->
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-12 col-md-12 col-12">
                            <div class="
                                    customer-label-group
                                    mt-2 mb-0
                                    d-flex
                                    justify-content-end
                                  ">
                                  <button type="button" title="View Details" class="btn custom-outline-view-btn me-2"
                                    @click="
                                      voterdetailModalOpen(data)
                                      ">
                                    <i class="pi pi-eye call-btn-color"></i> View
                                  </button>
                                  <button type="button" title="Edit Karyakartas Details" class="btn custom-outline-view-btn"
                                    @click="voterDetailEditModalOpen(data)" v-if="this.alloweditvoterfk == 1">
                                    <i class="pi pi-pencil call-btn-color"></i> Edit
                                  </button>
                                </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Edit Voter Detail start here -->
  <div class="modal-mask" v-if="editvoterdetailstatus">
    <div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered custom-modal-outer">
      <div class="modal-content">
        <div class="modal-header custom-custmer-header-border">
          <h5 class="modal-title" id="staticBackdropLabel">Edit Karyakartas Details</h5>
          <button type="button" class="btn-close" @click="voterDetailEditModalClose()"></button>
        </div>
        <!-- First Stepper Start Here -->
        <div class="px-4 pt-2">
          <div class="stepper-label-edit-voter">
            <span v-if="voterEditStepFirstStatus">Step<span class="stepper-label-circle-voter">1</span></span>
            <span v-if="voterEditStepTwoStatus">Step<span class="stepper-label-circle-voter">2</span></span>
            <span v-if="voterEditStepThrirdStatus">Step<span class="stepper-label-circle-voter">3</span></span>
          </div>
        </div>
        <Divider />
        <div class="modal-body modal-body-scroll-outer pt-3" v-if="voterEditStepFirstStatus">
          <div style="position: relative;
    min-height: 443px;">
            <div v-if="!showloadervotermodal">
              <div class="row">
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Mobile No.<span class="text-danger">*</span></label>
                    <div class="input-group custom-input-group">
                      <div class="col-lg-4 col-md-4 col-12">
                        <Multiselect v-model="countrycode" :options="countryCodeList" :searchable="true" label="label"
                          placeholder="Select" disabled class="multiselect-custom custom-title-multiselect"
                          :canClear="false" :closeOnSelect="true" :object="true" noOptionsText="No Result"
                          :canDeselect="false" />
                      </div>
                      <div class="col-lg-8 col-md-8 col-12">
                        <input type="text" v-model="edit.primobile" id="editmobileInput" disabled class="form-control"
                          placeholder="Enter Mobile No." maxlength="10" @paste="onPasteMobile" @keypress="onlymobileEditFormat" autocomplete="off" />
                      </div>
                    </div>
                    <div class="custom-error" v-if="v$.edit.primobile.$error">
                      {{ v$.edit.primobile.$errors[0].$message }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="custom-form-group">
                    <label for="formTitleInput" class="form-label">First Name<span class="text-danger">*</span></label>
                    <div class="input-group custom-input-group">
                      <div class="col-lg-4 col-md-4 col-12">
                        <Multiselect v-model="edit.title" disabled :options="titleList" :searchable="false" label="label"
                          placeholder="Select" class="multiselect-custom custom-title-multiselect text-capitalize"
                          :canClear="false" :closeOnSelect="true" :object="true" noOptionsText="No Result"
                          :canDeselect="false" @select="handleEditGender" />
                      </div>
                      <div class="col-lg-8 col-md-8 col-12">
                        <input type="text" v-model="edit.firstname" disabled class="form-control text-capitalize"
                          id="formFirstNameInput" placeholder="Enter First Name" autocomplete="off" maxlength="20"
                          @keypress="isLetterWithOutSpace($event)" />
                      </div>
                    </div>
                    <div class="custom-error" v-if="v$.edit.title.$error">
                      {{ v$.edit.title.$errors[0].$message }}
                    </div>
                    <div class="custom-error" v-if="edit.title != '' && v$.edit.firstname.$error">
                      {{ v$.edit.firstname.$errors[0].$message }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="custom-form-group">
                    <label for="formMiddleNameInput" class="form-label">Middle Name</label>
                    <input type="text" v-model="edit.middlename" :disabled="edit.middlename != '' || addvoterloader" class="form-control text-capitalize"
                      id="formMiddleNameInput" placeholder="Enter Middle Name" autocomplete="off" maxlength="20"
                      @keypress="isLetterWithOutSpace($event)" />
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="custom-form-group">
                    <label for="formLastNameInput" class="form-label">Last Name<span class="text-danger">*</span></label>
                    <input type="text" v-model="edit.lastname" disabled class="form-control text-capitalize" id="formLastNameInput"
                      placeholder="Enter Last Name" autocomplete="off" maxlength="20"
                      @keypress="isLetterWithOutSpace($event)" />
                    <div class="custom-error" v-if="v$.edit.lastname.$error">
                      {{ v$.edit.lastname.$errors[0].$message }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="custom-form-group">
                    <label for="formEmailInput" class="form-label">Email Id</label>
                    <input type="text" v-model="edit.email" :disabled="addvoterloader" class="form-control" id="formEmailInput"
                      placeholder="Enter Email Id" autocomplete="off" maxlength="50" />
                    <div class="custom-error" v-if="v$.edit.email.$error">
                      {{ v$.edit.email.$errors[0].$message }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Gender<span class="text-danger">*</span></label>
                    <Multiselect v-model="edit.gender" :disabled="addvoterloader" :options="edit.genderList" :searchable="false" label="label"
                      placeholder="Select" class="multiselect-custom" :canClear="false" :closeOnSelect="true"
                      :object="true" noOptionsText="No Result" />
                    <div class="custom-error" v-if="v$.edit.gender.$error">
                      {{ v$.edit.gender.$errors[0].$message }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="custom-form-group">
                    <label for="formBirthDateInput" class="form-label">Birth Date<span
                        class="text-danger">*</span></label>
                    <Calendar v-model="edit.birthdate" :disabled="addvoterloader" :minDate="minDateBirth" :maxDate="maxDateBirth" :showIcon="true"
                      class="custom-ultima-calendar" placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy" :manualInput="false"
                      :monthNavigator="true" :yearRange="'1950:' + maxDateBirth.getFullYear()" appendTo="body"
                      :yearNavigator="true">
                    </Calendar>
                    <div class="custom-error" v-if="v$.edit.birthdate.$error">
                      {{ v$.edit.birthdate.$errors[0].$message }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Lok Sabha Name<span class="text-danger">*</span></label>
                    <Multiselect v-model="edit.loksabha" :disabled="addvoterloader" :options="loksabhaList" :searchable="true"
                      @change="getvidhansabhaName($event)" @keypress="isLetterWithSpace($event)" label="label"
                      placeholder="Select" class="multiselect-custom text-capitalize" :canClear="false"
                      :closeOnSelect="true" :object="true" noOptionsText="No Result" />
                    <div class="custom-error" v-if="v$.edit.loksabha.$error">
                      {{ v$.edit.loksabha.$errors[0].$message }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Vidhan Sabha Name<span class="text-danger">*</span></label>
                    <Multiselect v-show="!vidhanloaderflag" :disabled="addvoterloader" v-model="edit.vidhansabha" :options="vidhansabhaList"
                      :searchable="true" label="label" placeholder="Select" @keypress="isLetterWithSpace($event)"
                      class="multiselect-custom text-capitalize" :canClear="false" :closeOnSelect="true" :object="true"
                      noOptionsText="No Result" />
                    <Multiselect v-show="vidhanloaderflag" loading :searchable="false" label="label" placeholder="Select"
                      class="multiselect-custom" noOptionsText="No Result" />
                    <div class="custom-error" v-if="v$.edit.vidhansabha.$error">
                      {{ v$.edit.vidhansabha.$errors[0].$message }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Blood Group</label>
                    <Multiselect v-model="edit.bloodgroup" :disabled="addvoterloader" :options="bloodgroupList" :searchable="true" label="label"
                      placeholder="Select" class="multiselect-custom" :canClear="true" :closeOnSelect="true"
                      :object="true" noOptionsText="No Result" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Profession</label>
                    <Multiselect v-model="edit.profession" :disabled="addvoterloader || this.allowmodifyvoterprofessionalinfo == 0" :options="professionList" :searchable="true" label="label"
                      placeholder="Select" class="multiselect-custom" :canClear="true" :closeOnSelect="true"
                      :object="true" noOptionsText="No Result" />
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12" v-show="!isCompanyNameExist">
                  <div class="custom-form-group">
                    <label class="form-label">Company / Business Name</label>
                    <Multiselect v-model="edit.companyname" :disabled="addvoterloader || this.allowmodifyvoterprofessionalinfo == 0" :options="companyList" :searchable="true" label="label"
                            placeholder="Search Company Name" class="multiselect-custom" :canClear="true" :closeOnSelect="true"
                            :object="true" noOptionsText="No Result Found" 
                      :createOption="false"  />
                      <label style="line-height: 14px;">
                          Not found?,
                          <span>
                              <a class="p-ai-center p-text-right cursor-pointer"
                                  style="color: #3f51b5" @click="toggleCompanyName">Click
                                  here to add</a>
                          </span>
                      </label>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12" v-show="isCompanyNameExist">
                  <div class="custom-form-group">
                    <label for="createcom" class="form-label">Add Company / Business Name</label>
                    <input type="text" class="form-control" :disabled="addvoterloader || this.allowmodifyvoterprofessionalinfo == 0" @input="createOtherCompanyName"
                      placeholder="Enter Company / Business Name" autocomplete="off" maxlength="30" />
                      <label style="line-height: 14px;">
                          For search,
                          <span>
                              <a class="p-ai-center p-text-right cursor-pointer"
                                  style="color: #3f51b5" @click="toggleCompanyName">Click
                                  here</a>
                          </span>
                      </label>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12" v-show="!isDesignationExist">
                  <div class="custom-form-group">
                    <label class="form-label">Designation</label>
                    <Multiselect v-model="edit.designation" :disabled="addvoterloader || this.allowmodifyvoterprofessionalinfo == 0" :options="clientdesignationList" :searchable="true"
                      label="label" placeholder="Search Designation" class="multiselect-custom text-capitalize"
                      :canClear="true" :closeOnSelect="true" noOptionsText="No Result Found" :object="true"
                      :createOption="false" />
                      <label style="line-height: 14px;">
                          Not found?,
                          <span>
                              <a class="p-ai-center p-text-right cursor-pointer"
                                  style="color: #3f51b5" @click="toggleDesginationName">Click
                                  here to add</a>
                          </span>
                      </label>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12" v-show="isDesignationExist">
                  <div class="custom-form-group">
                    <label for="createdesingation" class="form-label">Add Designation</label>
                    <input type="text" class="form-control" :disabled="addvoterloader || this.allowmodifyvoterprofessionalinfo == 0" @input="createOtherDesignationName"
                      placeholder="Enter Designation Name" autocomplete="off" maxlength="30" />
                      <label style="line-height: 14px;">
                          For search,
                          <span>
                              <a class="p-ai-center p-text-right cursor-pointer"
                                  style="color: #3f51b5" @click="toggleDesginationName">Click
                                  here</a>
                          </span>
                      </label>
                  </div>
                </div>
              </div>

              <div class="row">

                <div class="col-lg-4 col-md-4 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Select City<span class="text-danger">*</span></label>
                    <Multiselect v-show="!cityloaderflag" :disabled="addvoterloader" v-model="edit.city" :options="cityList" :searchable="true"
                      label="label" placeholder="Select" class="multiselect-custom" @keypress="isLetterWithSpace($event)"
                      :canClear="true" :closeOnSelect="true" noOptionsText="No Result" :object="true"
                      @select="getAreaByCityId(edit.city)" required />
                    <Multiselect v-show="cityloaderflag" loading :searchable="false" label="label" placeholder="Select"
                      class="multiselect-custom" noOptionsText="No Result" />
                    <div class="custom-error" v-if="v$.edit.city.$error">
                      {{ v$.edit.city.$errors[0].$message }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Select Area<span v-if="areaList !=null" class="text-danger">*</span></label>
                    <Multiselect v-show="!arealoaderflag" :disabled="addvoterloader" v-model="edit.area" :options="areaList" :searchable="true"
                      label="label" placeholder="Select" class="multiselect-custom" @keypress="isLetterWithSpace($event)"
                      :canClear="true" :closeOnSelect="true" noOptionsText="No Result" :object="true" required />
                    <Multiselect v-show="arealoaderflag" loading :searchable="false" label="label" placeholder="Select"
                      class="multiselect-custom" noOptionsText="No Result" />
                    <div class="custom-error" v-if="v$.edit.area.$error">
                      {{ v$.edit.area.$errors[0].$message }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Pincode<span class="text-danger">*</span></label>
                    <input type="text" v-model="edit.pincode" :disabled="addvoterloader" id="editpincodeInput" class="form-control text-capitalize"
                      placeholder="Enter Pincode" maxlength="6" @keypress="onlypincodeEditFormat" autocomplete="off" />
                    <div class="custom-error" v-if="v$.edit.pincode.$error">
                      {{ v$.edit.pincode.$errors[0].$message }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="custom-form-group">
                    <label for="formLandmarkInput" class="form-label">Nearest Landmark</label>
                    <input type="text" v-model="edit.landmark" :disabled="addvoterloader" class="form-control text-capitalize" id="formLandmarkInput"
                      placeholder="Enter Landmark" autocomplete="off" maxlength="50" />
                  </div>
                </div>
                <div class="col-lg-8 col-md-8 col-12">
                  <div class="custom-form-group">
                    <label for="formShortNameInput" class="form-label">Current Address<span
                        class="text-danger">*</span></label>
                    <textarea type="text" v-model="edit.fulladdress" :disabled="addvoterloader" class="form-control custom-text-area-scroll"
                      placeholder="Enter Full Address" autocomplete="off" rows="1" maxlength="250"></textarea>
                    <div class="custom-error" v-if="v$.edit.fulladdress.$error">
                      {{ v$.edit.fulladdress.$errors[0].$message }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12 col-md-12 col-12">
                  <div class="custom-form-group">
                    <label class="form-label d-flex justify-content-between"><span>Select
                        Tags</span><small class="muted-text text-primary">(Max
                        5)</small></label>
                    <Multiselect v-model="edit.tagname" :disabled="addvoterloader" :options="taggroupList" mode="tags" :createTag="false" label="label" :class="this.allowtagremoveaccess == 0 ? 'tagdisabled' : ''" class="multiselect-custom form-custom-select text-capitalize"
                    placeholder="Select" :closeOnSelect="true" :canClear="false" :searchable="false" :object="true" :max="5" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Upload Photo</label>
                    <FileUpload mode="basic" accept="image/*" :disabled="addvoterloader" :auto="true" ref="voterprofileimg"
                      class="custom-upload-btn-ultima" v-model="edit.voterprofileimg"
                      v-on:change="handleUploadVoterProfile()" />
                    <div class="custom-error" v-if="errorprofileimage">{{ errorprofileimage }}</div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12" v-if="exitpreviewurl == null">
                  <img v-if="edit.importprofilefile != null && edit.importprofilefile != ''" :src="edit.importprofilefile"
                    class="
                                    rounded
                                    bg-light
                                    me-auto
                                    d-block
                                    voter-photo-img
                                  " alt="" />
                  <img src="/assets/images/profile.svg" v-if="edit.importprofilefile == null" class="
                                    rounded
                                    bg-light
                                    me-auto
                                    d-block
                                    voter-photo-img
                                  " alt="" />
                </div>
                <div class="col-lg-4 col-md-4 col-12" v-if="exitpreviewurl != null">
                  <img :src="exitpreviewurl.objectURL" class="
                                    rounded
                                    bg-light
                                    me-auto
                                    d-block
                                    voter-photo-img
                                  " alt="" />
                </div>
                <div class="col-lg-4 col-md-4 col-12"
                  v-if="(edit.importprofilefile == null && edit.importprofilefile == '') && exitpreviewurl == null">
                  <img src="/assets/images/profile.svg" class="
                                    rounded
                                    bg-light
                                    me-auto
                                    d-block
                                    voter-photo-img
                                  " alt="" />
                </div>
                <div class="text-primary" v-if="exitpreviewurl != null">{{ exitpreviewurl.name }}</div>
              </div>
            </div>
            <div v-if="showloadervotermodal">
              <div class="custom-modal-spinner-loader">
                <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer" v-if="voterEditStepFirstStatus">
          <div class="col-12 text-end">
            <button type="button" class="btn modal-bulk-next-btn" @click="updateVoterDetailbtn()"
              :disabled="addvoterloader">
              <span v-if="!addvoterloader">Next</span>
              <div class="spinner-border text-light custom-spinner-loader-btn" role="status" v-if="addvoterloader">
                <span class="visually-hidden">Loading...</span>
              </div>
            </button>
          </div>
        </div>
        <!-- First Stepper End Here -->
        <!-- Second Stepper Start Here -->
        <div class="modal-body modal-body-scroll-outer pt-3" v-if="voterEditStepTwoStatus">
          <div style="position: relative;
min-height: 443px;">
            <div v-if="!showloadervotermodal">
              <div class="row">
                <div class="col-lg-8 col-md-8 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Voter Type<span class="text-danger">*</span></label>
                    <div class="custom-group-radio-box-btn">
                      <input type="radio" class="btn-check" @change="checkAddVoterAuth()" v-model="edituser.votertype"
                        name="impact-option" id="onlyvoter" autocomplete="off" value="1" />
                      <label class="btn custom-radio-box-btn text-capitalize" for="onlyvoter">Only
                        Voter</label>
                      <input type="radio" class="btn-check" @change="checkAddUserAuth()" v-model="edituser.votertype"
                        name="impact-option" id="karyakarta" autocomplete="off" value="2" />
                      <label class="btn custom-radio-box-btn text-capitalize" for="karyakarta">
                        Karyakartas</label>
                      <input type="radio" class="btn-check" @change="checkAddUserAuth()" v-model="edituser.votertype"
                        name="impact-option" id="user" autocomplete="off" value="3" />
                      <label class="btn text-capitalize" for="user">User</label>
                    </div>
                    <div class="custom-error" v-if="v$.edituser.votertype.$error">
                      {{ v$.edituser.votertype.$errors[0].$message }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" v-if="!exitvotererrormsg">
                <div class="col-lg-12 col-md-12 col-12">
                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-12" v-if="edituser.votertype == 2 || edituser.votertype == 3">
                      <div class="custom-form-group">
                        <label class="form-label">Designation<span class="text-danger">*</span></label>
                        <Multiselect v-model="edituser.userdesignation" :options="userdesignationList" :searchable="true"
                          label="label" placeholder="Select" class="multiselect-custom" :canClear="false"
                          :closeOnSelect="true" :object="true" noOptionsText="No Result" />
                        <div class="custom-error" v-if="v$.edituser.userdesignation.$error">
                          {{ v$.edituser.userdesignation.$errors[0].$message }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-12" v-if="edituser.votertype == 2 || edituser.votertype == 3">
                      <div class="custom-form-group">
                        <label class="form-label">Team Lead<span v-if="teamleadList != null"
                            class="text-danger">*</span></label>
                        <Multiselect v-model="edituser.teamleadname" :options="teamleadList" :searchable="true"
                          label="label" placeholder="Select" class="multiselect-custom text-capitalize" :canClear="false"
                          :closeOnSelect="true" :object="true" noOptionsText="No Result" />
                        <div class="custom-error" v-if="v$.edituser.teamleadname.$error">
                          {{ v$.edituser.teamleadname.$errors[0].$message }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" v-if="edituser.votertype == 2 || edituser.votertype == 3">
                    <div class="col-lg-5 col-md-5 col-12">
                      <div class="custom-form-group">
                        <label class="form-label">Permission Type<span class="text-danger">*</span></label>
                        <div class="custom-group-radio-box-btn">
                          <input type="radio" class="btn-check" v-model="edituser.roletype" name="role-option"
                            id="rolewise" autocomplete="off" value="1" />
                          <label class="btn custom-radio-box-btn text-capitalize" for="rolewise">Set
                            Wise</label>
                          <input type="radio" class="btn-check" v-model="edituser.roletype" name="role-option"
                            id="manualrole" autocomplete="off" value="2" />
                          <label class="btn text-capitalize" for="manualrole">Manual
                            Permissions</label>
                        </div>
                        <div class="custom-error" v-if="v$.edituser.roletype.$error">
                          {{ v$.edituser.roletype.$errors[0].$message }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12" v-if="edituser.roletype == 1">
                      <div class="custom-form-group">
                        <label class="form-label">Permission Set<span class="text-danger">*</span></label>

                        <Multiselect v-model="edituser.userrole" :options="userrole_list" :searchable="false"
                          label="label" placeholder="Select Permission Set" class="multiselect-custom text-capitalize"
                          :canClear="true" :closeOnSelect="true" />
                        <div class="custom-error" v-if="v$.edituser.userrole.$error && roletype == 1">
                          {{ v$.edituser.userrole.$errors[0].$message }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" v-if="edituser.roletype == 2 && (edituser.votertype == 2 || edituser.votertype == 3)">
                    <div class="col-12">
                      <div class="custom-form-group">
                        <label for="formLaModulesInput" class="form-label">Select Modules<span
                            class="text-danger">*</span></label>
                        <div class="flex flex-wrap gap-2 mb-2">
                          <button type="button" class="btn custom-view-detail-btn me-2" @click="expandAll"><i
                              class="pi pi-plus me-2" style="font-size: 0.7rem"></i>Expand All</button>
                          <button type="button" class="btn custom-view-detail-btn" @click="collapseAll"><i
                              class="pi pi-minus me-2" style="font-size: 0.7rem"></i>Collapse All</button>
                        </div>
                        <Tree :value="nodes" selectionMode="checkbox" v-model:selectionKeys="edituser.selectedTreeValue"
                          :expandedKeys="expandedKeys" class="custom-users-role">
                        </Tree>
                        <div class="custom-error"
                          v-if="v$.edituser.selectedTreeValue.$error && edituser.roletype == 2">
                          {{ v$.edituser.selectedTreeValue.$errors[0].$message }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" v-if="exitvotererrormsg">
                <div class="col-lg-12 col-md-12 col-12" v-if="voterexitstatus == 1">
                  <span class="custom-img-error">This Mobile Number already registered as an User.</span>
                </div>
                <div class="col-lg-12 col-md-12 col-12"
                  v-if="(voterexitid == '' || voterexitid == null) && (addharexitid == '' || addharexitid == null)">
                  <span class="custom-img-error">Please edit Voter and add Aadhar & Voter ID details.</span>
                </div>
              </div>
            </div>
            <div v-if="showloadervotermodal">
              <div class="custom-modal-spinner-loader">
                <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer" v-if="voterEditStepTwoStatus">
          <div class="col-12 text-end">
            <button type="button" class="btn modal-bulk-next-btn" @click="updateUserPermissionbtn()"
            :disabled="(voterexitid == '' || voterexitid == null) && (addharexitid == '' || addharexitid == null) && edituser.votertype != 1 || editvoterloader">
              <span v-if="!editvoterloader"><span v-if="edituser.votertype == 1">Submit</span><span
                  v-if="edituser.votertype != 1">Next</span></span>
              <div class="spinner-border text-light custom-spinner-loader-btn" role="status" v-if="editvoterloader">
                <span class="visually-hidden">Loading...</span>
              </div>
            </button>
          </div>
        </div>
        <!-- Second Stepper End Here -->
        <!-- Third Stepper Start Here -->
        <div class="modal-body modal-body-scroll-outer pt-3" v-if="voterEditStepThrirdStatus">
          <div style="position: relative;
min-height: 443px;">
            <div v-if="!showloadervotermodal">
              <div class="row">
                <div class="col-lg-12 col-md-12 col-12">
                    <div
                        class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                        <label class="from-label-value me-3">Allow to use Mobile App : </label>
                        <InputSwitch v-model="allow.usermobapppermission" />
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-12">
                    <div class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                        <label class="from-label-value me-3">Allow to make Outgoing Calls to voters :
                        </label>
                        <InputSwitch v-model="allow.displaymakeoutgoingcallvoterpermission" />
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-12">
                    <div
                        class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                        <label class="from-label-value me-3">Allow to display voter's Mobile Number :
                        </label>
                        <InputSwitch v-model="allow.usermobpermission" />
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-12">
                    <div
                        class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                        <label class="from-label-value me-3">Allow to display voter's Email ID :
                        </label>
                        <InputSwitch v-model="allow.useremailpermission" />
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-12">
                    <div
                        class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                        <label class="from-label-value me-3">Allow to display Aadhaar No. : </label>
                        <InputSwitch v-model="allow.useraadharpermission" />
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-12">
                    <div class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                        <label class="from-label-value me-3">Allow to add a Voter & Voter's Family :
                        </label>
                        <InputSwitch v-model="allow.displayaddvoterfamilypermission" />
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-12">
                    <div
                        class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                        <label class="from-label-value me-3">Allow to edit Voter Details manually :
                        </label>
                        <InputSwitch v-model="allow.usereditpermission" />
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-12">
                    <div class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                        <label class="from-label-value me-3">Allow to add Voter's Professional Information :
                        </label>
                        <InputSwitch v-model="allow.displayaddvoterprofessionalinfopermission" />
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-12">
                    <div class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                        <label class="from-label-value me-3">Allow to modify Voter's Professional Information :
                        </label>
                        <InputSwitch v-model="allow.displaymodifyvoterprofessionalinfopermission" />
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-12">
                    <div class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                        <label class="from-label-value me-3">Allow to manage or modify Family Relations of Voters :
                        </label>
                        <InputSwitch v-model="allow.displaymanagemodifyfamilyrelationpermission" />
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-12">
                    <div class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                        <label class="from-label-value me-3">Allow to remove a Member from Voter's Family Member Group :
                        </label>
                        <InputSwitch v-model="allow.displayremovememberfromfamilygrouppermission" />
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-12">
                    <div class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                        <label class="from-label-value me-3">Allow to Mark Voter / User / Karyakarta as a VIP / VVIP :
                        </label>
                        <InputSwitch v-model="allow.displaymarkvoteruservippermission" />
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-12">
                    <div class="customer-label-group">
                        <div class="customer-label-group">
                            <div class="d-flex align-items-center justify-content-between">
                                <label class="from-label-value me-3">Allow to Delete Voter : </label>
                                <InputSwitch @change="CheckAllowDeleteVoterAccess(allow.displaydeletevoterpermission)" v-model="allow.displaydeletevoterpermission" />
                            </div>
                            <small class="text-danger" v-if="this.deletevotermessage">{{this.deletevotermessage}}</small>
                        </div>
                    </div>
                </div>
              </div>
            </div>
            <div v-if="showloadervotermodal">
              <div class="custom-modal-spinner-loader">
                <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer" v-if="voterEditStepThrirdStatus">
          <div class="col-12 text-end">
            <button type="button" class="btn modal-bulk-next-btn" @click="updateClientUserPermission()"
              :disabled="editpermissionloader">
              <span v-if="!editpermissionloader">Submit</span>
              <div class="spinner-border text-light custom-spinner-loader-btn" role="status" v-if="editpermissionloader">
                <span class="visually-hidden">Loading...</span>
              </div>
            </button>
          </div>
        </div>
        <!-- Third Stepper End Here -->
      </div>
    </div>
  </div>
  <!-- Edit Voter Detail end here -->
  <!-- whatsApp History modal start here -->
  <div class="modal-mask" v-if="whatsappmodalsflag">
    <div class="modal-dialog modal-lg modal-dialog-end mt-0 mb-0 me-0 custom-modal-outer modal-dialog-scrollable vh-100">
      <div class="modal-content vh-100">
        <div class="modal-header whatsapp-header">
          <h5 class="modal-title" id="staticBackdropLabel">
            <div class="d-flex align-items-bottom">
              <div class="flex-shrink-0">
                <img
                  v-if="(this.whatsappvoterprofile == null || this.whatsappvoterprofile == '') && (this.whatsappaadharprofile == null || this.whatsappaadharprofile == '')"
                  src="/assets/images/profile.svg" class="profile-header-avatar rounded-circle" alt="whatsappprofile"
                  width="35" height="35" />
                <img v-if="this.whatsappvoterprofile != null && this.whatsappvoterprofile != ''"
                  :src="'https://storage.googleapis.com/' + this.voterstorageimgpath + '/voterphoto/' + this.whatsappvoterprofile"
                  class="profile-header-avatar rounded-circle" :alt="this.whatsappvoterprofile" width="35" height="35" />
                <img
                  v-if="(this.whatsappaadharprofile != null && this.whatsappaadharprofile != '') && (this.whatsappvoterprofile == null || this.whatsappvoterprofile == 'NULL' || this.whatsappvoterprofile == '')"
                  :src="'https://storage.googleapis.com/' + this.voterstorageimgpath + '/aadharphoto/' + this.whatsappaadharprofile"
                  class="profile-header-avatar rounded-circle" :alt="this.whatsappaadharprofile" width="35" height="35" />
              </div>
              <div class="flex-grow-1 ms-3">
                <div class="whatsapp-header-name text-capitalize">{{ this.whatsappname ? this.whatsappname : "N/A" }}
                </div>
                <div class="whatsapp-header-number" v-if="this.whatsappnumber != ''">
                  <span v-if="this.allowshowmobilekaryakartasfk == 0">{{ maskedNumber(this.whatsappnumber) }}</span>
                  <span v-if="this.allowshowmobilekaryakartasfk == 1">{{ this.whatsappnumber }}</span>
                </div>
              </div>
            </div>
          </h5>
          <div class="d-flex justify-content-end align-items-center">
            <InputSwitch v-model="ApiLoadingSwitch" class="me-3" @change="switchLoadingStatus()" />
            <button type="button" title="Refresh" class="btn custom-outline-refresh-btn me-3"
              @click="getwhatsappchatlogs()">
              <i class="pi pi-refresh refresh-btn-color"></i>
            </button>
            <button type="button" class="btn-close" @click="whatsappHistoryModalClose()"></button>
          </div>
        </div>
        <div class="modal-body p-0">
          <div class="chatbox-right-outer">
            <div class="chatbox-inner-content">
              <div class="widget-chat p-dialog-content">
                <div id="chatcontainer" class="conversation-container" v-if="!showloadermodal">
                  <div v-for="msg in whatsAppChatHistory" :key="msg">
                    <div class="message received" v-if="msg.lr3 == 1">
                      <div v-if="msg.lr20" class="mb-1">
                        <img @click="whatsappimagepreview(msg)" :src="msg.lr20"
                          style="vertical-align: middle; width:300px"
                          class="whatsapp-Sent-image p-mt-1 cursor-pointer whatsapp-imger-overlay" />
                      </div>
                      <div>
                        {{ decodeHtml(msg.lr4) }}
                      </div>
                      <a class="whatsapp-image-cursor" v-if="msg.lr6"><img :src="msg.lr6" style="vertical-align: middle"
                          class="whatsapp-received-image p-mt-1" /></a>
                      <!-- <a class="whatsapp-image-cursor" v-if="msg.ct9"><img :src="msg.ct9"
                                                                style="vertical-align: middle"
                                                                class="whatsapp-received-image p-mt-1" /></a>
                                                        <a class="whatsapp-image-cursor" v-if="msg.ct10"><img
                                                                :src="msg.ct10" style="vertical-align: middle"
                                                                class="whatsapp-received-image p-mt-1" /></a> -->
                      <span class="metadata"><span class="time">{{ format_timestamp(msg.lr2)
                      }}</span></span>
                    </div>

                    <div class="message sent" v-if="msg.lr3 == 2">
                      <div v-if="msg.lr20" class="mb-1">
                        <img @click="whatsappimagepreview(msg)" :src="msg.lr20"
                          style="vertical-align: middle; width:300px"
                          class="whatsapp-Sent-image p-mt-1 cursor-pointer whatsapp-imger-overlay" />
                      </div>
                      <div>
                        {{ decodeHtml(msg.lr4) }}
                      </div>
                      <a class="whatsapp-image-cursor" v-if="msg.lr6"><img :src="msg.lr6" style="vertical-align: middle"
                          class="whatsapp-Sent-image p-mt-1" /></a>
                      <!-- <a class="whatsapp-image-cursor" v-if="msg.ct9"><img :src="msg.ct9"
                                                                style="vertical-align: middle"
                                                                class="whatsapp-received-image p-mt-1" /></a>
                                                        <a class="whatsapp-image-cursor" v-if="msg.ct10"><img
                                                                :src="msg.ct10" style="vertical-align: middle"
                                                                class="whatsapp-received-image p-mt-1" /></a> -->
                      <span class="metadata"><span class="time">{{ format_timestamp(msg.lr2)
                      }}</span></span>
                    </div>
                  </div>
                </div>
                <div v-if="showloadermodal">
                  <div class="custom-modal-spinner-loader">
                    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer p-0">
          <div class="whatsapp-textbtn-outer">
            <div class="custom-chat-picture text-success" v-if="uploadfilename">
              File attatched
            </div>
            <div class="conversation-compose">
              <textarea class="form-control input-msg custom-chat-group-textarea" :autoResize="true" v-model="chatInput"
                rows="2" :class="this.disabledWhatsAppInputPermission == 0 ? 'cursor-not-allowed' : ''" :disabled="this.disabledWhatsAppInputPermission == 0" placeholder="Type Message here..." />
              <div class="send-btn-outer">
                <div class="d-flex align-items-center">
                  <div class="flex-shrink-0 ms-2">
                    <label class="fileUpload btn-default upload-document" :class="this.disabledWhatsAppInputPermission == 0 ? 'cursor-not-allowed' : 'cursor-pointer'">
                      <i class="pi pi-paperclip" style="font-size: 1rem" v-bind:style="[this.disabledWhatsAppInputPermission == 0 ? { color: '#e9ecef' } : '']"></i>

                      <input type="file" hidden="" name="uploadfile" ref="uploadfile" multiple
                        v-on:change="validateAttachment" :disabled="this.disabledWhatsAppInputPermission == 0" />
                    </label>
                  </div>
                  <div class="flex-grow-1 ms-3">
                    <button class="send" @click="sendMessage()" :disabled="this.disabledWhatsAppInputPermission == 0 || showloaderbtn">
                      <i class="pi pi-send" v-if="!showloaderbtn" style="font-size: 1rem"></i>
                      <div class="
                                            spinner-border
                                            text-light
                                            custom-spinner-loader-btn
                                          " role="status" v-if="showloaderbtn"></div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- whatsApp History modal end here -->
  <div v-if="ImagePreviewDialog" class="modal-mask">
    <div class="modal-dialog modal-md modal-dialog-scrollable modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Image Preview</h4>
          <button class="btn-close" @click="whatsappimagepreviewClose"></button>
        </div>
        <div class="modal-body p-0">
          <img :src="previewimg" style="vertical-align: middle; width: 100%;" />
        </div>
      </div>
    </div>
  </div>
    <!-- next action modal start here -->
    <div class="modal-mask" v-if="viewvoterdetailstatus">
    <div class="modal-dialog modal-lg modal-dialog-centered custom-modal-outer">
      <div class="modal-content">
        <div class="modal-header custom-custmer-header-border">
          <h5 class="modal-title" id="staticBackdropLabel">Karyakartas Details</h5>
          <button type="button" class="btn-close" @click="voterDetailModalClose()"></button>
        </div>
        <ul class="nav nav-tabs custom-customer-modal-tab" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link active" id="voter-idcard-tab" data-bs-toggle="tab" data-bs-target="#votercarddetails"
              type="button" role="tab" aria-controls="appointment-history" aria-selected="true"
              @click="getvoterdetails()">
              Voter Id
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="voter-aadharcard-tab" data-bs-toggle="tab" data-bs-target="#voteraadhardetails"
              type="button" role="tab" aria-controls="vehicle-details" aria-selected="false" @click="getaadhardetails()">
              Aadhar
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="voter-pancard-tab" data-bs-toggle="tab" data-bs-target="#voterpandetails"
              type="button" role="tab" aria-controls="customer-details" aria-selected="false" @click="getpandetails()">
              PAN
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="voter-driving-tab" data-bs-toggle="tab" data-bs-target="#voterdrivingdetails"
              type="button" role="tab" aria-controls="contact-history" aria-selected="false" @click="getlicensedetails">
              Driving Licence
            </button>
          </li>
          <!-- <li class="nav-item" role="presentation">
            <button disabled class="nav-link" id="voter-schemes-tab" data-bs-toggle="tab"
              data-bs-target="#voterschemesdetails" type="button" role="tab" aria-controls="customer-details"
              aria-selected="false" @click="selectedtab((e = 1))">
              Schemes
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button disabled class="nav-link" id="voter-history-tab" data-bs-toggle="tab"
              data-bs-target="#voterhistorydetails" type="button" role="tab" aria-controls="customer-details"
              aria-selected="false" @click="selectedtab((e = 2))">
              History
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="voter-hierarchy-tab" data-bs-toggle="tab" data-bs-target="#voterhierachydetails"
              type="button" role="tab" aria-controls="contact-history" aria-selected="false"
              @click="voterFamilyRelationList()">
              Family Hierarchy
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button disabled class="nav-link" id="voter-engagements-tab" data-bs-toggle="tab"
              data-bs-target="#voterengagementsdetails" type="button" role="tab" aria-controls="contact-history"
              aria-selected="false" @click="selectedtab((e = 6))">
              Engagements
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button disabled class="nav-link" id="voter-influencers-tab" data-bs-toggle="tab"
              data-bs-target="#voterinfluencersdetails" type="button" role="tab" aria-controls="contact-history"
              aria-selected="false" @click="selectedtab((e = 7))">
              Influencers
            </button>
          </li> -->
        </ul>
        <div class="modal-body modal-body-scroll-outer">
          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="votercarddetails" role="tabpanel"
              aria-labelledby="voter-idcard-tab">
              <div class="modal-scroll-min-height">
                <div v-if="!showloadervotermodal">
                  <div v-if="voterinputdisplayflag">
                    <div class="row" v-if="voterarraylength < 3">
                      <div class="col-12">
                        <div class="row">
                          <div class="col-lg-3 col-md-3 col-12">
                            <div class="custom-form-group">
                              <label for="formVoterInput" class="form-label">Voter Id
                                <span class="text-danger">*</span></label>
                              <input type="text" v-model="votercardnumber" class="form-control text-uppercase"
                                id="formVoterInput" @input="checkVoterId()" placeholder="Enter Voter Id"
                                autocomplete="off" maxlength="10" />
                              <span class="custom-error" v-if="voteriderr">
                                {{ voteriderr }}
                              </span>
                            </div>
                          </div>
                          <div class="
                              col-lg-4 col-md-4 col-12
                            ">
                            <div class="custom-form-group" style="margin-top: 26px;">
                              <button type="button" class="header-btn-outer btn btn-primary"
                                @click="fetchvoteriddetails()" :disabled="errStatus || votercardnumber.length == 0 || showloader
                                  " :style="{ width: '104px' }">
                                <span v-if="!showloader">Get Details</span>
                                <div class="
                                    spinner-border
                                    text-light
                                    custom-spinner-loader-btn
                                  " role="status" v-if="showloader">
                                  <span class="visually-hidden">Loading...</span>
                                </div>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div class="row mb-3" v-if="alreadyExitVoterMessage">
                          <div class="col-12">
                            <div class="custom-img-error">{{ this.alreadyExitVoterMessage }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Divider class="mt-0 mb-3" v-if="voterdivdisplayflag && voterarraylength < 3" />
                  <!-- open modal to display if voter ID was exits are Single Only start here -->
                  <div v-if="voterdivdisplayflag && voterarraylength == 1">
                    <div class="voter-detail-card mb-3" v-for="votergovdata in voterTabDetails" :key="votergovdata">
                      <div class="row mb-2">
                        <div class="text-end">
                          <span v-if="votergovdata.ab26 == 1" class="status-primary">Primary</span>
                          <span v-else-if="votergovdata.ab26 == 2" class="status-secondary">Secondary</span>
                          <span v-else class="status-other">Other</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Voter Number</label>
                            <div class="from-label-value text-uppercase">
                              {{
                                votergovdata.ak35 ? votergovdata.ak35 : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Name</label>
                            <div class="from-label-value text-capitalize">
                              {{
                                votergovdata.ak5 ? votergovdata.ak5 : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-2 col-md-2 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Age</label>
                            <div class="from-label-value">
                              {{
                                votergovdata.ak19 ? votergovdata.ak19 : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Gender</label>
                            <div class="from-label-value text-capitalize">
                              {{
                                votergovdata.ak22
                                ? votergovdata.ak22 == 1
                                  ? "Male"
                                  : votergovdata.ak22 == 2
                                    ? "Female"
                                    : "Others"
                                : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Part Name</label>
                            <div class="from-label-value text-capitalize">
                              {{
                                votergovdata.ab4 ? votergovdata.ab4 : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Part Number</label>
                            <div class="from-label-value">
                              {{
                                votergovdata.ak85 ? votergovdata.ak85 : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Polling Station</label>
                            <div class="from-label-value text-capitalize">
                              {{
                                votergovdata.ab7 ? votergovdata.ab7 : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Area</label>
                            <div class="from-label-value text-capitalize">
                              {{
                                votergovdata.ak44
                                ? votergovdata.ak44
                                : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">State</label>
                            <div class="from-label-value text-capitalize">
                              {{
                                votergovdata.ak48
                                ? votergovdata.ak48
                                : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Assembly Constituency</label>
                            <div class="from-label-value text-capitalize">
                              {{
                                votergovdata.ab22
                                ? votergovdata.ab22
                                : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Assembly Constituency Number</label>
                            <div class="from-label-value">
                              {{
                                votergovdata.ab23
                                ? votergovdata.ab23
                                : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Parliamentary Constituency</label>
                            <div class="from-label-value text-capitalize">
                              {{
                                votergovdata.ab24
                                ? votergovdata.ab24
                                : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- open modal to display if voter ID was exits are Single Only end here -->
                  <!-- open modal to display if voter ID was exits are Multiple start here -->
                  <div class="d-flex align-items-start" v-if="voterarraylength > 1">
                    <ul class="nav nav-tabs custom-vertical-modal-tab flex-column me-3" id="myTab" role="tablist">
                      <li class="nav-item" role="presentation" v-for="(votergovdata, index) in voterTabDetails"
                        :key="index">
                        <button class="nav-link" :class="votergovdata.ab26 == 1 ? 'active' : ''"
                          :id="'headingNumberSet' + index" data-bs-toggle="tab"
                          :data-bs-target="'#collapseVirtualNumber' + index" type="button" role="tab"
                          aria-selected="true">
                          <span class="d-flex justify-content-between align-items-center"><span>{{ votergovdata.ak35
                          }}</span>
                            <span class="user-badge status-active" v-if="votergovdata.ab26 == 1"><i
                                class="pi pi-check-circle text-success me-1"
                                :style="{ fontSize: '10px' }"></i>Primary</span></span>
                        </button>
                      </li>
                    </ul>
                    <div class="tab-content" id="myTabContent">
                      <div class="tab-pane fade" :class="votergovdata.ab26 == 1 ? 'show active' : ''"
                        :aria-labelledby="'headingNumberSet' + index" role="tabpanel"
                        :id="'collapseVirtualNumber' + index" v-for="(votergovdata, index) in voterTabDetails"
                        :key="index">
                        <div class="row" v-if="votergovdata.ab26 != 1">
                          <div class="col-lg-12 col-12 pe-4 text-end">
                            <label class="from-label-value me-3">Set as Primary Voter Id</label>
                            <InputSwitch @change="confirmModalOpen(votergovdata)" />
                          </div>
                        </div>
                        <Divider v-if="votergovdata.ab26 != 1" />
                        <div class="row">
                          <div class="col-lg-6 col-md-6 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Voter Number</label>
                              <div class="from-label-value text-uppercase">
                                {{
                                  votergovdata.ak35 ? votergovdata.ak35 : "N/A"
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Name</label>
                              <div class="from-label-value text-capitalize">
                                {{
                                  votergovdata.ak5 ? votergovdata.ak5 : "N/A"
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Age</label>
                              <div class="from-label-value">
                                {{
                                  votergovdata.ak19 ? votergovdata.ak19 : "N/A"
                                }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-lg-6 col-md-6 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Gender</label>
                              <div class="from-label-value text-capitalize">
                                {{
                                  votergovdata.ak22
                                  ? votergovdata.ak22 == 1
                                    ? "Male"
                                    : votergovdata.ak22 == 2
                                      ? "Female"
                                      : "Others"
                                  : "N/A"
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Part Name</label>
                              <div class="from-label-value text-capitalize">
                                {{
                                  votergovdata.ab4 ? votergovdata.ab4 : "N/A"
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Part Number</label>
                              <div class="from-label-value">
                                {{
                                  votergovdata.ak85 ? votergovdata.ak85 : "N/A"
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Polling Station</label>
                              <div class="from-label-value text-capitalize">
                                {{
                                  votergovdata.ab7 ? votergovdata.ab7 : "N/A"
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Area</label>
                              <div class="from-label-value text-capitalize">
                                {{
                                  votergovdata.ak44
                                  ? votergovdata.ak44
                                  : "N/A"
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">State</label>
                              <div class="from-label-value text-capitalize">
                                {{
                                  votergovdata.ak48
                                  ? votergovdata.ak48
                                  : "N/A"
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Assembly Constituency</label>
                              <div class="from-label-value text-capitalize">
                                {{
                                  votergovdata.ab22
                                  ? votergovdata.ab22
                                  : "N/A"
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Assembly Constituency Number</label>
                              <div class="from-label-value">
                                {{
                                  votergovdata.ab23
                                  ? votergovdata.ab23
                                  : "N/A"
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Parliamentary Constituency</label>
                              <div class="from-label-value text-capitalize">
                                {{
                                  votergovdata.ab24
                                  ? votergovdata.ab24
                                  : "N/A"
                                }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- open modal to display if voter ID was exits are Multiple end here -->
                  <!-- when enter voter id display confirmation detail to update start here -->
                  <div v-if="voterupdatedisplayflag">
                    <div class="voter-detail-card mb-3">
                      <div class="row">
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Voter Number</label>
                            <div class="from-label-value text-uppercase">
                              {{
                                votergovconfirmdata.ak35 ? votergovconfirmdata.ak35 : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Name</label>
                            <div class="from-label-value text-capitalize">
                              {{
                                votergovconfirmdata.ak5 ? votergovconfirmdata.ak5 : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-2 col-md-2 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Age</label>
                            <div class="from-label-value">
                              {{
                                votergovconfirmdata.ak19 ? votergovconfirmdata.ak19 : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-2 col-md-2 col-12 text-end" v-if="updateVoterBtnStatus">
                          <button type="button" @click="confirmUpdateModalOpen(votergovconfirmdata)"
                            class="btn custom-view-detail-btn" :disabled="showupdateloader" :style="{ width: '70px' }">
                            <span v-if="!showupdateloader">Confirm</span>
                            <div class="spinner-border custom-spinner-icon-loader-btn" role="status"
                              v-if="showupdateloader">
                              <span class="visually-hidden">Loading...</span>
                            </div>
                          </button>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Gender</label>
                            <div class="from-label-value text-capitalize">
                              {{
                                votergovconfirmdata.ak22
                                ? votergovconfirmdata.ak22 == 1
                                  ? "Male"
                                  : votergovconfirmdata.ak22 == 2
                                    ? "Female"
                                    : "Others"
                                : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Part Name</label>
                            <div class="from-label-value text-capitalize">
                              {{
                                votergovconfirmdata.ab4 ? votergovconfirmdata.ab4 : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Part Number</label>
                            <div class="from-label-value">
                              {{
                                votergovconfirmdata.ak85 ? votergovconfirmdata.ak85 : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Polling Station</label>
                            <div class="from-label-value text-capitalize">
                              {{
                                votergovconfirmdata.ab7 ? votergovconfirmdata.ab7 : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Area</label>
                            <div class="from-label-value text-capitalize">
                              {{
                                votergovconfirmdata.ak44
                                ? votergovconfirmdata.ak44
                                : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">State</label>
                            <div class="from-label-value text-capitalize">
                              {{
                                votergovconfirmdata.ak48
                                ? votergovconfirmdata.ak48
                                : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Assembly Constituency</label>
                            <div class="from-label-value text-capitalize">
                              {{
                                votergovconfirmdata.ab22
                                ? votergovconfirmdata.ab22
                                : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Assembly Constituency Number</label>
                            <div class="from-label-value">
                              {{
                                votergovconfirmdata.ab23
                                ? votergovconfirmdata.ab23
                                : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Parliamentary Constituency</label>
                            <div class="from-label-value text-capitalize">
                              {{
                                votergovconfirmdata.ab24
                                ? votergovconfirmdata.ab24
                                : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- when enter voter id display confirmation detail to update end here -->
                </div>
                <div v-if="showloadervotermodal">
                  <div class="custom-modal-spinner-loader">
                    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="voteraadhardetails" role="tabpanel" aria-labelledby="voter-aadharcard-tab">
              <div class="modal-scroll-min-height">
                <div v-if="!showloaderaadharmodal">
                  <div v-if="aadhardivdisplayflag == 1">
                    <div class="row">
                      <div class="
                          col-lg-2 col-md-2 col-12
                          d-flex
                          align-items-center
                        ">
                        <div class="customer-label-group">
                          <div class="d-flex align-items-center">
                            <div>
                              <img v-if="AadharTabDetails.ak33 == null ||
                                AadharTabDetails.ak33 == ''
                                " src="/assets/images/profile.svg" class="rounded bg-light me-auto d-block" alt=""
                                width="90" />
                              <img :src="'https://storage.googleapis.com/' + this.voterstorageimgpath + '/aadharphoto/' + AadharTabDetails.ak33" class="rounded bg-light me-auto d-block" alt="" width="90"
                                v-if="AadharTabDetails.ak33 != null &&
                                  AadharTabDetails.ak33 != ''
                                  " />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="row">
                          <div class="col-lg-12 col-md-12 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Name</label>
                              <div class="from-label-value text-capitalize">
                                {{
                                  AadharTabDetails.ak5
                                  ? AadharTabDetails.ak5
                                  : "N/A"
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-12 col-md-12 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Aadhar Number</label>
                              <div class="from-label-value">
                              {{ maskedNumber(AadharTabDetails.ak36) ? maskedNumber(AadharTabDetails.ak36) : "N/A" }}
                            </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-12 text-end" v-if="aadharupdatebtnflag">
                        <button type="button" @click="confirmAadharUpdateModalOpen(AadharTabDetails.ak36)"
                          class="btn custom-view-detail-btn" :disabled="showupdateloader" :style="{ width: '70px' }">
                          <span v-if="!showupdateloader">Update</span>
                          <div class="spinner-border custom-spinner-icon-loader-btn" role="status"
                            v-if="showupdateloader">
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        </button>
                      </div>
                      <div class="col-lg-6 col-md-6 col-12 text-end" v-if="aadharUpdateBtnDaysMsg">
                        <div class="col-12">
                          <div class="custom-img-error">{{ this.aadharUpdateBtnDaysMsg }}</div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Date of Birth</label>
                          <div class="from-label-value">
                            {{ format_date(AadharTabDetails.ak18) }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Gender</label>
                          <div class="from-label-value">
                            {{
                              AadharTabDetails.ak22
                              ? AadharTabDetails.ak22 == 1
                                ? "Male"
                                : AadharTabDetails.ak22 == 2
                                  ? "Female"
                                  : "Others"
                              : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">House</label>
                          <div class="from-label-value text-capitalize">
                            {{
                              AadharTabDetails.ak148
                              ? AadharTabDetails.ak148
                              : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Landmark</label>
                          <div class="from-label-value text-capitalize">
                            {{
                              AadharTabDetails.ak50
                              ? AadharTabDetails.ak50
                              : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Street</label>
                          <div class="from-label-value text-capitalize">
                            {{
                              AadharTabDetails.ak147
                              ? AadharTabDetails.ak147
                              : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Location</label>
                          <div class="from-label-value text-capitalize">
                            N/A
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Post Office</label>
                          <div class="from-label-value text-capitalize">
                            {{
                              AadharTabDetails.ak92
                              ? AadharTabDetails.ak92
                              : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Area</label>
                          <div class="from-label-value text-capitalize">
                            {{
                              AadharTabDetails.ak44
                              ? AadharTabDetails.ak44
                              : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">City</label>
                          <div class="from-label-value text-capitalize">
                            {{
                              AadharTabDetails.ak46
                              ? AadharTabDetails.ak46
                              : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Sub Dist</label>
                          <div class="from-label-value text-capitalize">
                            {{
                              AadharTabDetails.ak146
                              ? AadharTabDetails.ak146
                              : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">State</label>
                          <div class="from-label-value text-capitalize">
                            {{
                              AadharTabDetails.ak48
                              ? AadharTabDetails.ak48
                              : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-8 col-md-8 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Address</label>
                          <div class="from-label-value text-capitalize">
                            {{
                              AadharTabDetails.ak42
                              ? AadharTabDetails.ak42
                              : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Pincode</label>
                          <div class="from-label-value">
                            {{
                              AadharTabDetails.ak97
                              ? AadharTabDetails.ak97
                              : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Last Updated Date</label>
                          <div class="from-label-value">
                            {{ format_date(AadharTabDetails.ak137)}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="aadhardivdisplayflag == 0">
                    <div class="row">
                      <div class="col-12">
                        <div class="row">
                          <div class="col-lg-4 col-md-4 col-12">
                            <div class="custom-form-group">
                              <label for="formaadharcardinput" class="form-label">Aadhar No.
                                <span class="text-danger">*</span></label>
                              <input type="text" @input="checkAadharNumber()" v-model="aadharcardnumber" class="form-control text-uppercase"
                                id="formaadharcardinput" placeholder="Enter Aadhar No." autocomplete="off" maxlength="12"
                                @keypress="onlyNumber" @paste="onPasteMobile" :disabled="displayMobilePopUp" />
                              <span class="custom-error" v-if="aadharerr">
                                {{ aadharerr }}
                              </span>
                              <span class="custom-error" v-if="sendOTPMessage">{{ sendOTPMessage }}</span>
                            </div>
                          </div>
                          <div class="
                              col-lg-2 col-md-2 col-12
                            " v-if="!displayMobilePopUp">
                            <div class="custom-form-group" style="margin-top: 26px;">
                              <button type="button" class="header-btn-outer btn btn-primary" :style="{ width: '100px' }"
                                @click="sendAadharOtp()" :disabled="aadharcardnumber.length != 12 || showloader">
                                <span v-if="!showloader">Send OTP</span>
                                <div class="
                                    spinner-border
                                    text-light
                                    custom-spinner-loader-btn
                                  " role="status" v-if="showloader">
                                  <span class="visually-hidden">Loading...</span>
                                </div>
                              </button>
                            </div>
                          </div>
                          <div class="col-lg-4 col-md-4 col-12" v-if="displayMobilePopUp">
                            <div class="custom-form-group">
                              <label for="formaadharcardinput" class="form-label">Enter OTP <span
                                  class="text-danger">*</span></label>
                              <input type="text" v-model="aadharotp" class="form-control otp-input-space"
                                id="formaadharcardinput" placeholder="Enter OTP" autocomplete="off" maxlength="6"
                                minlength="6" @keypress="onlyNumber" @paste="onPasteMobile" :disabled="mobile_otp_status == 1" />
                              <div class="d-flex align-items-center justify-content-between">
                                <span class="custom-error" v-if="mobileotperr">
                                  {{ mobileotperr }}
                                </span>
                                <span v-if="timerMobileStatus" class="otp-counter-text mt-1">{{
                                  timerMobileInterval
                                }}</span>
                                <button @click="resendAadharOtp()" class="btn btn-link aadhar-resent-otp-btn"
                                  v-if="resentotpbtnstatus" :style="{ width: '80px' }" :disabled="showresentloader">
                                  <span v-if="!showresentloader">Resend OTP</span>
                                  <div class="spinner-border custom-spinner-icon-loader-btn" role="status"
                                    v-if="showresentloader">
                                    <span class="visually-hidden">Loading...</span>
                                  </div>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div class="
                              col-lg-2 col-md-2 col-12
                            " v-if="displayMobilePopUp">
                            <div class="custom-form-group" style="margin-top: 26px;">
                              <button type="button" class="header-btn-outer btn btn-success" @click="verifyAadharOtp()"
                                :style="{ width: '80px' }" :disabled="aadharotp.length != 6 || showloader">
                                <span v-if="!showloader">Verify</span>
                                <div class="
                                    spinner-border
                                    text-light
                                    custom-spinner-loader-btn
                                  " role="status" v-if="showloader">
                                  <span class="visually-hidden">Loading...</span>
                                </div>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div class="row" v-if="alreadyExitMessage">
                          <div class="col-12">
                            <div class="custom-img-error">{{ this.alreadyExitMessage }}</div>
                          </div>
                        </div>
                        <div class="row" v-if="systemlimiterrormsg">
                          <div class="col-lg-12 col-md-12 col-12">
                            <span class="custom-img-error">{{ systemlimiterrormsg }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="showloaderaadharmodal">
                  <div class="custom-modal-spinner-loader">
                    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="voterpandetails" role="tabpanel" aria-labelledby="voter-pancard-tab">
              <div class="modal-scroll-min-height">
                <div v-if="!showloaderpanmodal">
                  <div v-if="pandivdisplayflag == 1">
                    <div class="row">
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Pan Number</label>
                          <div class="from-label-value text-uppercase">
                            {{ PanTabDetails.ad3 ? PanTabDetails.ad3 : "N/A" }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Name</label>
                          <div class="from-label-value text-capitalize">
                            {{ PanTabDetails.ad4 ? PanTabDetails.ad4 : "N/A" }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Category</label>
                          <div class="from-label-value">
                            {{
                              PanTabDetails.ad5 == 1
                              ? "Person"
                              : PanTabDetails.ad5 == 2
                                ? "Business"
                                : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="pandivdisplayflag == 0">
                    <div class="row">
                      <div class="col-12">
                        <div class="row">
                          <div class="col-lg-4 col-md-4 col-12">
                            <div class="custom-form-group">
                              <label for="formpancardinput" class="form-label">Pan No
                                <span class="text-danger">*</span></label>
                              <input type="text" v-model="pancardnumber" class="form-control text-uppercase"
                                id="formpancardinput" placeholder="Enter Pan Card No" autocomplete="off" maxlength="10" />
                              <span class="custom-error" v-if="errpan">
                                {{ errpan }}
                              </span>
                              <span class="custom-error" v-if="errmsg">
                                {{ errmsg }}
                              </span>
                            </div>
                          </div>
                          <div class="
                              col-lg-4 col-md-4 col-12
                            ">
                            <div class="custom-form-group" style="margin-top: 26px;">
                              <button type="button" class="header-btn-outer btn btn-primary" @click="getpancarddetails()"
                                :disabled="errStatus || pancardnumber.length == 0 || showloader
                                  " :style="{ width: '104px' }">
                                <span v-if="!showloader">Get Details</span>
                                <div class="
                                    spinner-border
                                    text-light
                                    custom-spinner-loader-btn
                                  " role="status" v-if="showloader">
                                  <span class="visually-hidden">Loading...</span>
                                </div>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="showloaderpanmodal">
                  <div class="custom-modal-spinner-loader">
                    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="voterdrivingdetails" role="tabpanel" aria-labelledby="voter-driving-tab">
              <div class="modal-scroll-min-height">
                <div v-if="!showloaderdrivingmodal">
                  <div v-if="drivingdivdisplayflag == 1">
                    <div class="row">
                      <div class="col-lg-2 col-md-2 col-12 d-flex align-items-center">
                        <div class="customer-label-group">
                          <div class="d-flex align-items-center">
                            <div>
                              <img v-if="LicenseTabDetails.ac18 == null ||
                                LicenseTabDetails.ac18 == ''
                                " src="/assets/images/profile.svg" class="rounded bg-light me-auto d-block" alt=""
                                width="90" />
                              <img :src="'https://storage.googleapis.com/' + this.voterstorageimgpath + '/drivingphoto/' + LicenseTabDetails.ac18" class="rounded bg-light me-auto d-block" alt=""
                                width="90" v-if="LicenseTabDetails.ac18 != null ||
                                  LicenseTabDetails.ac18 != ''
                                  " />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="row">
                          <div class="col-lg-12 col-md-12 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">License Number</label>
                              <div class="from-label-value text-uppercase">
                                {{
                                  LicenseTabDetails.ac3
                                  ? LicenseTabDetails.ac3
                                  : "N/A"
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-12 col-md-12 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Name</label>
                              <div class="from-label-value text-capitalize">
                                {{
                                  LicenseTabDetails.ac4
                                  ? LicenseTabDetails.ac4
                                  : "N/A"
                                }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-12">
                        <div class="row">
                          <div class="col-lg-12 col-md-12 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Date of Issue</label>
                              <div class="from-label-value">
                                {{ format_date(LicenseTabDetails.ac14) }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-12 col-md-12 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Date of Expiry</label>
                              <div class="from-label-value">
                                {{ format_date(LicenseTabDetails.ac13) }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Date of Birth</label>
                          <div class="from-label-value">
                            {{ format_date(LicenseTabDetails.ac12) }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Gender</label>
                          <div class="from-label-value">
                            {{
                                LicenseTabDetails.ac11
                                ? LicenseTabDetails.ac11 == 1
                                  ? "Male"
                                  : LicenseTabDetails.ac11 == 2
                                    ? "Female"
                                    : "Others"
                                : "N/A"
                              }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Father / Husband Name</label>
                          <div class="from-label-value text-capitalize">
                            {{
                              LicenseTabDetails.ac15
                              ? LicenseTabDetails.ac15
                              : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-8 col-md-8 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Permanent Address</label>
                          <div class="from-label-value">
                            {{
                              LicenseTabDetails.ac5 ? LicenseTabDetails.ac5 : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Permanent Pincode</label>
                          <div class="from-label-value">
                            {{
                              LicenseTabDetails.ac6 ? LicenseTabDetails.ac6 : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-8 col-md-8 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Temporary Address</label>
                          <div class="from-label-value">
                            {{
                              LicenseTabDetails.ac7 ? LicenseTabDetails.ac7 : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Temporary Pincode</label>
                          <div class="from-label-value">
                            {{
                              LicenseTabDetails.ac8 ? LicenseTabDetails.ac8 : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Ola Name</label>
                          <div class="from-label-value">
                            {{
                              LicenseTabDetails.ac9 ? LicenseTabDetails.ac9 : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Ola Code</label>
                          <div class="from-label-value">
                            {{
                              LicenseTabDetails.ac10
                              ? LicenseTabDetails.ac10
                              : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="drivingdivdisplayflag == 0">
                    <div class="row">
                      <div class="col-12">
                        <div class="row">
                          <div class="col-lg-4 col-md-4 col-12">
                            <div class="custom-form-group">
                              <label for="formdrivinginput" class="form-label">Driving Licence No
                                <span class="text-danger">*</span></label>
                              <input type="text" v-model="drivingnumber" class="form-control text-uppercase"
                                id="formdrivinginput" placeholder="Enter Driving Licence No" autocomplete="off"
                                maxlength="15" />
                              <span class="custom-error" v-if="errdriving">
                                {{ errdriving }}
                              </span>
                              <span class="custom-error" v-if="errmsg">
                                {{ errmsg }}
                              </span>
                            </div>
                          </div>
                          <div class="col-lg-4 col-md-4 col-12">
                            <div class="custom-form-group">
                              <label for="formBirthDateInput" class="form-label">Birth Date<span
                                  class="text-danger">*</span></label>
                              <Calendar v-model="licencebirthdate" :minDate="minDateBirth" :maxDate="maxDateBirth"
                                :showIcon="true" class="custom-ultima-calendar" placeholder="DD/MM/YYYY"
                                dateFormat="dd/mm/yy" :manualInput="false" :monthNavigator="true"
                                :yearRange="'1950:' + maxDateBirth.getFullYear()" appendTo="body" :yearNavigator="true">
                              </Calendar>
                            </div>
                          </div>
                          <div class="
                            col-lg-4 col-md-4 col-12
                          ">
                            <div class="custom-form-group" style="margin-top: 26px;">
                              <button type="button" class="header-btn-outer btn btn-primary" @click="getdrivingdetails()"
                                :disabled="errStatus || drivingnumber.length == 0 || this.licencebirthdate == null || showloader"
                                :style="{ width: '104px' }">
                                <span v-if="!showloader">Get Details</span>
                                <div class="
                                  spinner-border
                                  text-light
                                  custom-spinner-loader-btn
                                " role="status" v-if="showloader">
                                  <span class="visually-hidden">Loading...</span>
                                </div>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="showloaderdrivingmodal">
                  <div class="custom-modal-spinner-loader">
                    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="voterschemesdetails" role="tabpanel" aria-labelledby="voter-schemes-tab">
              <div v-if="selected_tab == 1">
                <VoterSchemeDetailModalTabs />
              </div>
            </div>
            <div class="tab-pane fade" id="voterhistorydetails" role="tabpanel" aria-labelledby="voter-history-tab">
              <div v-if="selected_tab == 2">
                <VoterHistoryDetailsModalaTabs />
              </div>
            </div>
            <div class="tab-pane fade" id="voterhierachydetails" role="tabpanel" aria-labelledby="voter-hierarchy-tab">
              <div>
                <OrganizationCharts />
              </div>
            </div>
            <div class="tab-pane fade" id="voterengagementsdetails" role="tabpanel"
              aria-labelledby="voter-engagements-tab">
              <div v-if="selected_tab == 6">
                <EngagementTotalVoterModal />
              </div>
            </div>
            <div class="tab-pane fade" id="voterinfluencersdetails" role="tabpanel"
              aria-labelledby="voter-influencers-tab">
              <div v-if="selected_tab == 7">
                <MaintenerTotalVoterModal />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- next action modal end here -->
  <!-- confirmation update Voter Card as a primary modal start here -->
  <div class="modal-mask" v-if="confirm_popup_status">
    <div class="modal-dialog modal-xs modal-dialog-centered custom-modal-outer confirm-dialog-modal">
      <div class="modal-content text-center">
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="confirm-dialog-header">Confirmation</div>
            </div>
            <div class="col-12">
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0">
                  <i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
                </div>
                <div class="flex-grow-1 ms-3 confirm-dialog-body">
                  Are you sure you want to proceed?
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="col-12 text-end">
            <button type="button" :disabled="showconfirmprivoterloaderbtn" class="btn confirm-modal-no-btn"
              @click="confirmModalClose()">
              No
            </button>
            <button type="button" class="btn confirm-modal-yes-btn" @click="changePrimaryVoterStatus()"
              :disabled="showconfirmprivoterloaderbtn">
              <span v-if="!showconfirmprivoterloaderbtn">Yes</span>

              <div class="spinner-border text-light custom-spinner-loader-btn" role="status"
                v-if="showconfirmprivoterloaderbtn">
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- confirmation update Voter Card as a primary modal end here -->
  <!-- filter start here -->
  <div class="modal-mask" v-if="voterfiltermodalsflag">
    <div class="
        modal-md modal-dialog-scrollable modal-fullscreen
        ms-auto
        custom-modal-outer
      ">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Filter By</h5>
          <button type="button" class="btn-close" @click="voterFilterModalClose()"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="row">
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">System Voter No.</label>
                    <input type="text" v-model="ak120" class="form-control" placeholder="Enter Part Number" minlength="2"
                      maxlength="7" @keypress="onlyNumber" @paste="onPasteMobile" autocomplete="off" />
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Gender</label>
                    <Multiselect v-model="ak22" :options="genderList" mode="tags" :createTag="false" label="label"
                      class="multiselect-custom text-capitalize" placeholder="Select"
                      :closeOnSelect="true" :searchable="false" :object="false" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-5 col-md-5 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Age</label>
                    <input type="text" v-model="agerange" class="form-control" placeholder="Enter Age" minlength="1"
                      maxlength="2" @paste="onPasteMobile" @keypress="onlysingleagechange" autocomplete="off" />
                    <span class="custom-error" v-if="agerangeeerr">
                      {{ agerangeeerr }}
                    </span>
                  </div>
                </div>
                <div class="col-lg-2 col-md-2 col-12">
                  <div class="customer-label-group text-center" style="margin-top: 35px;">
                    <label class="from-label-value">-- OR --</label>
                  </div>
                </div>
                <div class="col-lg-5 col-md-5 col-12">
                  <div class="row custom-form-group">
                    <div class="col-lg-5 col-md-5 col-12">
                      <div class="custom-form-group mb-0">
                        <label class="form-label">Age Range</label>
                        <input type="text" v-model="agerangefrom" class="form-control" placeholder="From" minlength="1"
                          maxlength="2" @keypress="agerangechange" autocomplete="off" />
                      </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-12">
                      <div class="custom-form-group text-center mb-0" style="margin-top: 35px;">-</div>
                    </div>
                    <div class="col-lg-5 col-md-5 col-12">
                      <div class="custom-form-group mb-0" style="margin-top: 5px;">
                        <label class="form-label"></label>
                        <input type="text" v-model="agerangeto" :disabled="agerangefrom == ''" class="form-control"
                          placeholder="To" minlength="1" maxlength="2" @keypress="agerangechange" autocomplete="off" />
                      </div>
                    </div>
                    <span class="custom-error" v-if="agerangefromeerr">
                      {{ agerangefromeerr }}
                    </span>
                    <span class="custom-error" v-if="agerangetoeerr">
                      {{ agerangetoeerr }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Pincode</label>
                    <input type="text" v-model="pincodevalue" id="filterpincodeInput" class="form-control" placeholder="Enter Pincode"
                      minlength="6" maxlength="6" @keypress="onlypincodeFilterFormat" autocomplete="off" />
                    <span class="custom-error" v-if="pincodeerr">
                      {{ pincodeerr }}
                    </span>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Area</label>
                    <Multiselect v-model="ak44" :options="filterareaList" mode="tags" :createTag="false"  :searchable="true" label="label"
                      placeholder="Select" class="multiselect-custom text-capitalize" :canClear="true"
                      :closeOnSelect="true" :object="false" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Voter Id</label>
                    <input type="text" v-model="votercardnumber" class="form-control text-uppercase"
                      placeholder="Enter Voter Id" maxlength="10" autocomplete="off" />
                    <span class="custom-error" v-if="voteriderr">
                      {{ voteriderr }}
                    </span>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="custom-form-group mb-0">
                    <label class="form-label">Aadhar No.</label>
                    <input type="text" v-model="aadharcardnumber" class="form-control text-uppercase"
                      id="formaadharcardinput" placeholder="Enter Aadhar No." autocomplete="off" minlength="12"
                      maxlength="12" @input="checkAadharNumber()" @paste="onPasteMobile" @keypress="onlyNumber" />
                    <span class="custom-error" v-if="aadharerr">
                      {{ aadharerr }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Profession</label>
                    <Multiselect v-model="profession" :options="voterprofessionList" mode="tags" :createTag="false"
                      label="label" class="multiselect-custom form-custom-select text-capitalize" placeholder="Select"
                      :closeOnSelect="true" :searchable="true" :object="false" />
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Part Number</label>
                    <Multiselect v-model="ak85" :options="reassignpartnoList" mode="tags" :createTag="false" label="label"
                      class="multiselect-custom form-custom-select text-capitalize" placeholder="Select"
                      :closeOnSelect="true" :searchable="true" :object="true" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Blood Group</label>
                    <Multiselect v-model="ak20" :options="bloodgroupList" mode="tags" :createTag="false"  :searchable="true" label="label"
                      placeholder="Select" class="multiselect-custom text-capitalize" :canClear="true"
                      :closeOnSelect="true" :object="false" />
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Select Tags</label>
                    <Multiselect v-model="ak121" :options="tagfiltergroupList" mode="tags" :createTag="false" label="label"
                      class="multiselect-custom form-custom-select text-capitalize" placeholder="Select"
                      :closeOnSelect="true" :searchable="true" :object="false" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="col-12 m-0">
            <div class="d-flex justify-content-between">
              <button class="btn filter-apply-btn"
                :disabled="(profession == '' && ak20 == '' && ak22 == '' && agerange == '' && agerangefrom == '' && agerangeto == '' && pincodevalue == '' && ak44 == '' && votercardnumber == '' && aadharcardnumber == '' && ak85 == '' && ak120 == '' && ak121 == '') || (this.agerangeeerr != '' || this.agerangefromeerr != '' || this.agerangetoeerr != '' || this.pincodeerr != '' || this.voteriderr != '' || this.aadharerr != '')"
                @click="
                  btnFilterSearch(
                    profession,
                    ak20,
                    ak22,
                    agerange,
                    agerangefrom,
                    agerangeto,
                    pincodevalue,
                    ak44,
                    votercardnumber,
                    aadharcardnumber,
                    ak85,
                    ak120,
                    ak121
                  )
                  ">
                Apply Filter
              </button>
              <button class="btn btn-link filter-reset-btn" @click="resetVoterFilter">
                Reset All
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- filter end here -->
    <!-- confirmation Update Aadhar Details modal start here -->
    <div class="modal-mask" v-if="confirm_aadhar_update_popup_status">
    <div class="modal-dialog modal-xs modal-dialog-centered custom-modal-outer confirm-dialog-modal">
      <div class="modal-content text-center">
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="confirm-dialog-header">Confirmation</div>
            </div>
            <div class="col-12">
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0">
                  <i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
                </div>
                <div class="flex-grow-1 ms-3 confirm-dialog-body">
                  Are you sure you want to proceed?
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="col-12 text-end">
            <button type="button" :disabled="showaconfirmloaderbtn" class="btn confirm-modal-no-btn"
              @click="confirmAadharUpdateModalClose()">
              No
            </button>
            <button type="button" class="btn confirm-modal-yes-btn" @click="updateAadharReloadPermission()"
              :disabled="showaconfirmloaderbtn">
              <span v-if="!showaconfirmloaderbtn">Yes</span>

              <div class="spinner-border text-light custom-spinner-loader-btn" role="status" v-if="showaconfirmloaderbtn">
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- confirmation Update Aadhar Details modal end here -->
</template>
<script>
import { required, helpers, requiredIf, minLength, maxLength, email } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import EventService from '../../service/EventService';
import ApiService from "../../service/ApiService";
import moment from "moment";
export default {
  inject: ["global"],
  data() {
    return {
      v$: useValidate(),
      mobile_number: "",
      voterfiltermodalsflag: false,
      voterbulkwhatsappmodalsflag: false,
      ak6: "",
      ak24: "",
      profession: [],
      ak20: [],
      ak21: "",
      ak22: [],
      ak19: "",
      ak19from: '',
      ak19to: '',
      ak49: "",
      ak44: [],
      ak35: "",
      ak36: '',
      ak85: [],
      ak120: '',
      ak121: [],
      agerangefrom: '',
      agerangeto: '',
      voterprofessionList: [],
      genderList: [
        { label: "Male", value: 1 },
        { label: "Female", value: 2 },
        { label: "Others", value: 3 },
      ],
      bloodgroupList: [],
      ageList: [],
      karyakartaList: [],
      totalRecords: 0,
      page_no: 0,
      selected_tab: 1,
      loading: false,
      callstatus: 0,
      selectedvoter: null,
      searchmobile: '',
      searchmobloader: false,
      searchfullname: '',
      searchnameloader: false,
      searchmobbtnactive: false,
      searchnamebtnactive: false,
      client_info: '',
      voterstorageimgpath: '',
      user_info: '',
      allowshowmobilekaryakartasfk: '',
      allowshowemailvoterkaryakartasfk: '',
      alloweditvoterfk: '',
      voterEditStepFirstStatus: false,
      voterEditStepTwoStatus: false,
      voterEditStepThrirdStatus: false,
      edituser: {
        votertype: '',
        roletype: '',
        userrole: "",
        userdesignation: "",
        teamleadname: '',
        selectedTreeValue: [],
      },
      allow: {
        usermobpermission: '',
        useremailpermission: '',
        useraadharpermission: '',
        usermobapppermission: true,
        usereditpermission: '',
        displaydeletevoterpermission: '',

        displayaddvoterfamilypermission: '',
        displayaddvoterprofessionalinfopermission: '',
        displaymodifyvoterprofessionalinfopermission: '',
        displaymanagemodifyfamilyrelationpermission: '',
        displayremovememberfromfamilygrouppermission: '',
        displaymarkvoteruservippermission: '',
        displaymakeoutgoingcallvoterpermission: '',
      },
      hidesearchname: false,
      hidesearchmob: false,
      aadharcardnumber: "",
      pincodevalue: '',
      agerange: '',
      votercardnumber: "",
      aadharerr: "",
      pincodeerr: '',
      agerangeeerr: '',
      agerangefromeerr: '',
      agerangetoeerr: '',
      voteriderr: "",
      errmsg: "",
      virtualNumberCount: '',
      virtualNumberList: '',
      taggroupList: [],
      tagfiltergroupList: [],
      reassignpartnoList: [],
      editvoterdetailstatus: false,
      countrycode: { value: 101, label: "+91" },
      countryCodeList: [],
      titleList: [],
      edit: {
        primobile: "",
        title: '',
        firstname: '',
        middlename: '',
        lastname: '',
        email: '',
        gender: '',
        genderList: [
          { label: "Male", value: 1 },
          { label: "Female", value: 2 },
          { label: "Other", value: 3 },
        ],
        birthdate: null,
        loksabha: '',
        vidhansabha: '',
        companyname: '',
        designation: '',
        importprofilefile: '',
        editRowId: '',
        voterprofileimg: '',
        fulladdress: '',
        city: "",
        area: "",
        pincode: '',
        landmark: '',
        bloodgroup: '',
        profession: '',
        tagname: [],
      },
      cityList: [],
      areaList: [],
      vidhanloaderflag: false,
      cityloaderflag: false,
      arealoaderflag: false,
      professionList: [
        { label: "Student", value: 1 },
        { label: "Self Employed", value: 2 },
        { label: "Salaried", value: 3 },
        { label: "Business", value: 4 },
        { label: "Farmer", value: 5 },
        { label: "Retired", value: 6 },
        { label: "Housewife", value: 7 },
      ],
      loksabhaList: [],
      vidhansabhaList: [],
      maxDateBirth: moment().subtract(1, "years")._d,
      minDateBirth: moment().subtract(120, "years")._d,
      clientdesignationList: [],
      exitpreviewurl: null,
      addvoterloader: false,
      errorprofileimage: '',
      userdesignationList: [],
      teamleadList: [],
      editvoterloader: false,
      userrole_list: [],
      expandedKeys: {},
      nodes: [],
      editpermissionloader: false,
      voterexitstatus: '',
      exitvotererrormsg: false,
      showloadervotermodal: false,
      // whatsapp changes start
      whatsappmodalsflag: false,
      showloadermodal: false,
      whatsApprowId: "",
      whatsAppChatHistory: [],
      whatsappname: "",
      whatsappnumber: "",
      whatsappvoterprofile: "",
      whatsappaadharprofile: "",
      ApiLoadingSwitch: false,
      showloaderbtn: false,
      uploadfile: null,
      file1: null,
      uploadfilename: null,
      chatInput: "",
      ImagePreviewDialog: false,
      companyList: [],
      // whatsapp changes end
     
      clientuserfiltermodals: false,
      clientusermodals: false,
      userstatus: null,
      mobilenumber: "",
      searchuser: "",
      filterflag: false,
      filterButtonShow: false,
      dissableFilterButton: false,
      update: {
        title: '',
        firstname: '',
        middlename: '',
        lastname: '',
        userdesignation: "",
        teamleadname: '',
        superuser: 1,
        userstatusflag: '',
        roletype: "",
        userrole: "",
        selectedTreeValue: [],
        activeStatusList: [
          { label: "Active", value: 1 },
          { label: "Temporary Suspended", value: 2 },
          { label: "Permanent Suspended", value: 3 },
        ],
      },
      rolesList: [],

      userid: '',
    
      edituserloader: false,
      usermob: '',
      localmobno: '',

   
      voterEditStepStatus: false,

    
      user_storage_fk: '',
   
      unlockbtnloader: false,
    
      ak82: '',
      ak101: '',
      confirm_popup_status: false,
      confirm_unlock_popup_status: false,
      showconfirmloaderbtn: false,
    
      viewvoterdetailstatus: false,
      pancardnumber: "",
  
 
      drivingnumber: "",
      licencebirthdate: null,
      errpan: "",
   
   
      errdriving: "",
   
      showloader: false,
      errStatus: false,
    
      voterdivdisplayflag: false,
      drivingdivdisplayflag: 0,
      pandivdisplayflag: 0,
      aadhardivdisplayflag: 0,
      mobile_otp_status: false,
      addvotermobile: "",
      addvotershowloader: false,
      aadharotp: "",

      displayMobilePopUp: false,
      timerMobileInterval: null,
      timerMobileStatus: false,
      timerMobileCount: 120,
      mobileotperr: "",
      msg: [],
     
      showloaderaadharmodal: false,
      showloaderpanmodal: false,
      showloaderdrivingmodal: false,
      reassignbtnloader: false,
      showloaderreassignmodal: false,
      assignuserList: [],
      user_team_status_allow: 0,
      alreadyExitMessage: '',
      sendOTPMessage: '',
      alreadyExitVoterMessage: '',
      votergovconfirmdata: [],
      showupdateloader: false,
      confirm_update_popup_status: false,
      confirm_aadhar_update_popup_status: false,
      showresentloader: false,
      resentotpbtnstatus: false,
      updateVoterBtnStatus: true,
      voterNoFK: '',
      showaconfirmloaderbtn: false,
      aadhar_number_fk: '',
      aadharupdatebtnflag: true,
      usercreateerrormsg: '',
      systemlimiterrormsg: '',

      voterexitid: '',
      addharexitid: '',
      callTrigerStatusList: '',
      
      // campaign start
      bulkcampaignmodalflag: false,
      bulkwhatsapploader: false,
      capwhats: {
        selectbulkvoter: '',
        whatsapptemplate: '',
        datevariable: null,
        campaignname: ''
      },
      whatsapptemplateList: [],
      todaysDate: new Date(),
      bulkcallloader: false,
      capcall: {
        bulktunefile: null,
        datevariable: null,
        campaignname: '',
        calltemplate: '',

      },
      errorbulktunefile: '',
      exitbulkmp3previewurl: null,
      getcampaigntemplateList: [],
      userList: [],
      voterupdatedisplayflag: false,
      voterarraylength: 0,
      voterinputdisplayflag: false,
      switchuncheck: false,
      dynamicTrueActiveValue: '',
      dynamicFalseInactiveValue: '',
      showconfirmprivoterloaderbtn: false,
      confirm_tune_id: '',
      voteridToSwitch: '',
      confirm_status_value: '',
      voteridstatusfk: '',
      familyrelationstatus: '',
      familyTabDetails: '',
      rowId: "",
      AadharTabDetails: '',
      voterTabDetails: "",
      LicenseTabDetails: "",
      PanTabDetails: "",
      messageList: [],
      submitted: false,
      errormsg: "",
      allowshowemailvoterfk: '',
      allowshowaadharvoterfk: '',
      allowtouseroutgoingcall: 0,
      reasign: {
        assigntype: 1,
        reassignpartno: [],
        reassigntouser: '',
      },
      updatevoterid: "",
      aadharUpdateBtnDaysMsg: '',
      filterareaList: [],
      isCompanyNameExist: false,
      isDesignationExist: false,
      disabledWhatsAppInputPermission: '',
      allowshowemailkaryakartasfk: '',
      deletevotermessage: '',
      allowmodifyvoterprofessionalinfo: 0,
      allowtagadditionaccess: '',
      allowtagremoveaccess: '',
    };
  },
  ApiService: null,
  eventService: null,
  created() {
    this.ApiService = new ApiService();
    this.eventService = new EventService();
  },
  watch: {
    pancardnumber(pancardnumber) {
      this.errmsg = "";
      this.validatePanCardNumber(pancardnumber);
      if (pancardnumber.length == 0) {
        this.errpan = "";
        this.errStatus = false;
      }
    },
    aadharcardnumber(aadharcardnumber) {
      this.errmsg = "";
      this.validateAadharCardNumber(aadharcardnumber);
      if (aadharcardnumber.length == 0) {
        this.aadharerr = "";
        this.errStatus = false;
      }
    },
    votercardnumber(votercardnumber) {
      this.errmsg = "";
      this.validateVoterIdNumber(votercardnumber);
      if (votercardnumber.length == 0) {
        this.voteriderr = "";
        this.errStatus = false;
      }
    },
    pincodevalue(pincodevalue) {
      this.errmsg = "";
      this.validatePinCodeNumber(pincodevalue);
      if (pincodevalue.length == 0) {
        this.pincodeerr = "";
        this.errStatus = false;
      }
    },
    agerange(agerange) {
      this.errmsg = "";
      this.validateAgeRangeNumber(agerange);
      if (agerange.length == 0) {
        this.agerangeeerr = "";
        this.errStatus = false;
      }
    },
    agerangefrom(agerangefrom) {
      this.errmsg = "";
      this.validateAgeRangeFromNumber(agerangefrom);
      if (agerangefrom.length == 0) {
        this.agerangefromeerr = "";
        this.errStatus = false;
      }
    },
    agerangeto(agerangeto) {
      this.errmsg = "";
      this.validateAgeRangeToNumber(agerangeto);
      if (agerangeto.length == 0) {
        this.agerangetoeerr = "";
        this.errStatus = false;
      }
    },
    drivingnumber(drivingnumber) {
      this.errmsg = "";
      this.validateDrivingNumber(drivingnumber);
      if (drivingnumber.length == 0) {
        this.errdriving = "";
        this.errStatus = false;
      }
    },
  },
  validations() {
    return {
      aadharotp: {
        required: helpers.withMessage("Enter OTP", required),
        minLength: minLength(4),
        maxLength: maxLength(4),
      },
      edit: {
        primobile: {
          required: helpers.withMessage('Please enter Mobile No.', required), minLength: helpers.withMessage(
            "Mobile no should be min 10 digit",
            minLength(10)
          )
        },
        title: { required: helpers.withMessage('Please select title', required) },
        firstname: {
          required: helpers.withMessage("Please enter first name", required),
        },
        lastname: {
          required: helpers.withMessage("Please enter last name", required),
        },
        email: {
          email: helpers.withMessage('Email address is invalid', email)
        },
        birthdate: { required: helpers.withMessage('Please select birth date', required) },
        loksabha: { required: helpers.withMessage('Please select lok sabha', required) },
        gender: { required: helpers.withMessage('Please select gender', required) },
        vidhansabha: { required: helpers.withMessage('Please select vidhan sabha', required) },
        fulladdress: { required: helpers.withMessage('Please enter full address', required) },
        city: { required: helpers.withMessage('Please select city', required) },
        pincode: {
          required: helpers.withMessage('Please enter pincode', required),
          minLength: helpers.withMessage(
            "Pincode should be min 6 length",
            minLength(6)
          ),
        },
        area: {
          requiredIf: helpers.withMessage(
            "Please select area",
            requiredIf(
              this.areaList != null
            )
          ),
        },
      },
      edituser: {
        votertype: { required: helpers.withMessage('Please select voter type', required) },
        roletype: {
          requiredIf: helpers.withMessage(
            "Please select role type",
            requiredIf(
              this.edituser.votertype == 2 || this.edituser.votertype == 3
            )
          ),
        },
        userrole: {
          requiredIf: helpers.withMessage(
            "Please select role designation",
            requiredIf(
              this.edituser.roletype == 1 && this.edituser.votertype == 2 || this.edituser.votertype == 3
            )
          ),
        },
        selectedTreeValue: {
          requiredIf: helpers.withMessage(
            "Please select module",
            requiredIf(
              this.edituser.roletype == 2
            )
          ),
        },
        userdesignation: {
          requiredIf: helpers.withMessage(
            "Please select designation",
            requiredIf(
              this.edituser.votertype == 2 || this.edituser.votertype == 3
            )
          ),
        },
        teamleadname: {
          requiredIf: helpers.withMessage(
            "Please select team lead name",
            requiredIf(
                 (this.edituser.votertype == 2 || this.edituser.votertype == 3) && this.teamleadList != null
            ),
          ),
        },
      },
    };
  },
  mounted() {
    this.getkaryakartalist({
      page_no: this.page_no,
      ak6: this.ak6,
      ak24: this.ak24,
      profession: this.profession,
      ak21: this.ak21,
      ak22: this.ak22,
      ak19: this.ak19,
      ak19from: this.ak19from,
      ak19to: this.ak19to,
      ak49: this.ak49,
      ak44: this.ak44,
      ak35: this.ak35,
      ak36: this.ak36,
      ak85: this.ak85,
      ak120: this.ak120,
      ak121: this.ak121
    });
    this.getcallbacknumbers();
    this.getbloodgroups();
    this.gettags();
    this.getAgeEvents();
    this.getboothnumberlist();
    this.getalltitle();
    this.expandAll();
    this.getloksabha();
    this.getclientdesignations();
    this.getClientRoles();
    this.getuserdesignation();
    this.getcompanylist();
    this.getactiveusers();
    this.getModules();
    this.getareaList();
    this.client_info = JSON.parse(localStorage.client_info);
    this.voterstorageimgpath = this.client_info.maa24;
    this.user_info = JSON.parse(localStorage.user);
    this.alloweditvoterfk = this.user_info.ak109;
    this.allowshowmobilekaryakartasfk = this.user_info.ak125;
    this.allowshowemailvoterkaryakartasfk = this.user_info.ak126;
    this.allowshowemailkaryakartasfk = this.user_info.ak126;
    this.allowmodifyvoterprofessionalinfo = this.user_info.ak200;
    if(this.user_info.ak203) {
        this.allowtouseroutgoingcall = this.user_info.ak203;
    }
    this.allowtagadditionaccess = this.user_info.ak197;
    this.allowtagremoveaccess = this.user_info.ak198;
    // if (this.user_info.ak165 == 1) {
    //   this.allow.displaydeletevoterpermission = true;
    // } else {
    //   this.allow.displaydeletevoterpermission = false;
    // }
  },
  beforeUnmount() {
        clearInterval(this.timer3);
    },
  methods: {
    createOtherCompanyName(e) {
        this.edit.companyname = { value: 0, label: e.target.value };
    },
    toggleCompanyName() {
        this.isCompanyNameExist = !this.isCompanyNameExist;
        this.edit.companyname = '';
    },
    createOtherDesignationName(e) {
        this.edit.designation = { value: 0, label: e.target.value };
    },
    toggleDesginationName() {
        this.isDesignationExist = !this.isDesignationExist;
        this.edit.designation = '';
    },
    // call trigger function start here
    getcompanylist(e) {
      this.ApiService.getcompanylist(e).then((data) => {
        if (data.status == 200) {
          this.companyList = data.data;
        } else {
          this.companyList = null;
        }
      });
    },
    getcallbacknumbers(e) {
      this.ApiService.getcallbacknumbers(e).then((data) => {
        if (data.success == true) {
          this.virtualNumberList = data.records;
          this.virtualNumberCount = data.count;
        }
      });
    },
    getboothnumberlist(e) {
      this.ApiService.getboothnumberlist(e).then((data) => {
        if (data.status == 200) {
          this.reassignpartnoList = data.data;
        }
      });
    },
    toggleOperatorCallList(id, event) {
      this.$refs[id].toggle(event);
    },

    CallTrigerOperatorNumber(voterid, mobileno, settingid, event) {
      setTimeout(() => {
        this.$refs[voterid].hide(event);
      }, 1000);
      this.ApiService.triggercalltomeraneta({ voterid: voterid, mobile_number: mobileno, settingId: settingid }).then(
        (items) => {
          if (items.success === true) {
            this.callstatus = 0;
            var successMsg = items.message;
            this.$toast.open({
              message: successMsg,
              type: "success",
              duration: 3000,
              position: "top-right",
            });
          } else {
            var errorMsg = items.message;
            this.$toast.open({
              message: errorMsg,
              type: "error",
              duration: 3000,
              position: "top-right",
            });
            this.callstatus = 0;
          }
        }
      );
    },
    // call trigger function end here
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }
    },
    onPasteMobile(event) {
      let mobvalue = event.clipboardData.getData('text');
      if (/^[0-9]+$/.test(mobvalue)) return true;
      else event.preventDefault();
    },
    mobileSearchFormat($event) {
        let keyCode = $event.keyCode ? $event.keyCode : $event.which;
        if (keyCode < 48 || keyCode > 57) {
            $event.preventDefault();
        }

        var mobileInput = document.getElementById("searchmobInput");
        if ((mobileInput.value == '' || mobileInput.length > 1) && (keyCode == 48 || keyCode == 49 || keyCode == 50 || keyCode == 51 || keyCode == 52)) {
            $event.preventDefault();
        }
    },
    selectedtab(e) {
      this.selected_tab = e;
    },

    voterFilterModalOpen() {
      this.getProfessions();
      this.voterfiltermodalsflag = true;
    },
    voterFilterModalClose() {
      this.voterfiltermodalsflag = false;
    },
     getProfessions(e) {
      this.ApiService.getProfessions(e).then((data) => {
        if (data.status == 200) {
          this.voterprofessionList = data.data;
        } else {
          this.voterprofessionList = null;
        }
      });
    },
    gettags(e) {
      this.ApiService.gettags(e).then((data) => {
        if (data.status == 200) {
          this.tagfiltergroupList = data.data;
          if(this.allowtagadditionaccess == 1) {
            this.taggroupList = data.data;
          } else {
            this.taggroupList = null;
          }
        } else {
          this.tagfiltergroupList = null;
          this.taggroupList = null;
        }
      });
    },
    getcities(e) {
      this.cityloaderflag = true;
      this.edit.city = '';
      this.edit.area = '';
      this.ApiService.getcities(e).then((data) => {
        if (data.success == true) {
          this.cityList = data.data;
          this.cityloaderflag = false;
          this.showloadervotermodal = false;
          this.areaList = null;
        } else {
          this.cityloaderflag = false;
          this.cityList = [];
          this.showloadervotermodal = false;
          this.areaList = null;
        }
      });
    },
    getAreaByCityId(e) {
      this.arealoaderflag = true;
      this.edit.area = '';
      this.ApiService.getareas({ city_id: e.value }).then((data) => {
        if (data.success == true) {
          this.areaList = data.data;
          this.arealoaderflag = false;
        } else {
          this.arealoaderflag = false;
          this.areaList = null;
        }
      });
    },
    getModules(ev) {
      this.ApiService.getModules(ev).then(data => {
        if (data.status == 200) {
          this.nodes = data.data;
        }
      })
    },
    getClientRoles(e) {
      this.ApiService.getclientroles(e).then((data) => {
        if (data.status == 200) {
          this.userrole_list = data.data;
        }
      });
    },
    getbloodgroups(e) {
      this.ApiService.getbloodgroups(e).then((data) => {
        if (data.status == 200) {
          this.bloodgroupList = data.data;
        } else {
          this.bloodgroupList = null;
        }
      });
    },
    getAgeEvents(e) {
      this.eventService.getEvents(e).then((data) => {
        this.ageList = data;
      });
    },
    btnFilterSearch(
      profession,
      ak21,
      ak22,
      agerange,
      agerangefrom,
      agerangeto,
      pincodevalue,
      ak44,
      votercardnumber,
      aadharcardnumber,
      ak85,
      ak120,
      ak121
    ) {
      if (!this.agerangeeerr && !this.pincodeerr && !this.voteriderr && !this.aadharerr && !this.agerangefromeerr && !this.agerangetoeerr) {
        this.voterfiltermodalsflag = false;
        this.profession = profession;
        this.ak21 = ak21;
        this.ak22 = ak22;
        this.agerange = agerange;
        this.agerangefrom = agerangefrom;
        this.agerangeto = agerangeto;
        this.pincodevalue = pincodevalue;
        this.ak44 = ak44;
        this.votercardnumber = votercardnumber;
        this.aadharcardnumber = aadharcardnumber;
        this.ak85 = ak85;
        this.ak120 = ak120;
        this.ak121 = ak121;
        this.getkaryakartalist({
          page_no: this.page_no,
          profession: this.profession,
          ak21: this.ak21,
          ak22: this.ak22,
          ak19: this.agerange,
          ak19from: this.agerangefrom,
          ak19to: this.agerangeto,
          ak49: this.pincodevalue,
          ak44: this.ak44,
          ak35: this.votercardnumber,
          ak36: this.aadharcardnumber,
          ak85: this.ak85,
          ak120: this.ak120,
          ak121: this.ak121
        });
      }
    },
    resetVoterFilter() {
      this.profession = null;
      this.ak21 = "";
      this.ak22 = null;
      this.agerange = "";
      this.agerangefrom = '';
      this.agerangeto = '';
      this.pincodevalue = "";
      this.ak44 = null;
      this.aadharcardnumber = "";
      this.votercardnumber = '';
      this.agerangeeerr = '';
      this.pincodeerr = '';
      this.voteriderr = '';
      this.aadharerr = '';
      this.ak85 = null;
      this.ak120 = '';
      this.ak121 = null;
      this.getkaryakartalist({
        page_no: this.page_no,
        isTeam: 0,
      });
      this.voterfiltermodalsflag = false;
    },
    getkaryakartalist(e) {
      this.loading = true;
      this.ApiService.getkaryakartalist(e).then((data) => {
        if (data.success === true) {
          this.karyakartaList = data.data;
          this.totalRecords = data.count;
          this.loading = false;
          this.searchnameloader = false;
          this.searchmobloader = false;
          this.hidesearchname = false;
          this.hidesearchmob = false;
        } else {
          this.karyakartaList = null;
          this.totalRecords = 0;
          this.loading = false;
          this.searchnameloader = false;
          this.searchmobloader = false;
          this.hidesearchname = false;
          this.hidesearchmob = false;
        }
      });
    },
    isLetterWithSpace(e) {
      let charspace = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z\s]+$/.test(charspace)) return true;
      else e.preventDefault();
    },

    changePage(event) {
      this.page_no = event.page;
      this.getkaryakartalist({ page_no: this.page_no });
    },
    redirecttoreport() {
      this.$router.push("/karyakartas/report");
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      } else {
        return 'N/A';
      }
    },
    format_timestamp(value) {
      if (value) {
        var localTime = new Date(value * 1000).toUTCString();
        return moment(localTime).format("DD/MM/YYYY @ hh:mm A");
      } else {
        return 'N/A';
      }
    },
    maskedNumber(getvalue) {
      const maskedDigits = 'X'.repeat(getvalue.length - 4);
      const FirstThreeDigits = getvalue.slice(0, 2);
      const lastThreeDigits = getvalue.slice(-2);
      return FirstThreeDigits + maskedDigits + lastThreeDigits;
    },
    maskedEmail(getvalue) {
        const [name, domain] = getvalue.split('@');
        const [firstDomain, sublastdomain, lastdomain] = domain.split('.');
        if(name.length == 1 && !lastdomain) {
            return `${name}@${new Array(firstDomain.length).join('*')}.${sublastdomain}`;
        } else if(name.length == 1 && lastdomain) {
            return `${name}@${new Array(firstDomain.length).join('*')}.${sublastdomain}.${lastdomain}`;
        } else {
            return `${name[0]}${new Array(name.length).join('*')}@${domain}`;
        }
    },
    searchMobVoter(
      searchmobile
    ) {
      this.hidesearchmob = true;
      this.searchmobloader = true;
      this.ak24 = searchmobile;
      this.getkaryakartalist({
        page_no: this.page_no,
        ak24: this.ak24,
      });
      this.searchmobbtnactive = true;
    },
    searchMobVoterStop() {
      this.searchmobbtnactive = false;
      this.searchmobile = "";
      this.getkaryakartalist({
        page_no: this.page_no,
      });
      this.searchmobloader = false;
    },
    searchFnameVoter(
      searchfullname
    ) {
      this.hidesearchname = true;
      this.searchnameloader = true;
      this.ak6 = searchfullname;
      this.getkaryakartalist({
        page_no: this.page_no,
        ak6: this.ak6,
      });
      this.searchnamebtnactive = true;
    },
    searchNameVoterStop() {
      this.searchnamebtnactive = false;
      this.searchfullname = "";
      this.getkaryakartalist({
        page_no: this.page_no,
      });
      this.searchnameloader = false;
    },
    validateAadharCardNumber(e) {
      this.errStatus = false;
      if (!this.validAadharCard(e)) {
        this.aadharerr = "Please enter valid Aadhar No.";
        this.errStatus = true;
      }
      if (this.errStatus == false) {
        this.aadharerr = "";
        return true;
      }
    },

    validAadharCard: function (e) {
      var re = /^((?!(0))[0-9]{12})$/;
      return re.test(e);
    },
    validatePinCodeNumber(e) {
      this.errStatus = false;
      if (!this.validPinCode(e)) {
        this.pincodeerr = "Please enter valid pincode";
        this.errStatus = true;
      }
      if (this.errStatus == false) {
        this.pincodeerr = "";
        return true;
      }
    },
    validPinCode: function (e) {
      var re = /^((?!(0))[0-9]{6,6})$/;
      return re.test(e);
    },
    validateAgeRangeNumber(e) {
      this.errStatus = false;
      if (!this.validAgeRange(e)) {
        this.agerangeeerr = "Please enter minimum 1 digit";
        this.errStatus = true;
      }
      if (this.agerange == 0) {
        this.agerangeeerr = "Please enter valid age";
        this.errStatus = true;
      }
      if (this.errStatus == false) {
        this.agerangeeerr = "";
        return true;
      }
    },
    validAgeRange: function (e) {
      var re = /([0-9]){1,2}$/;
      return re.test(e);
    },
    validateAgeRangeFromNumber(e) {
      this.errStatus = false;
      if (!this.validAgeFromRange(e)) {
        this.agerangefromeerr = "Please enter minimum 1 digit";
        this.errStatus = true;
      }
      if (this.agerangefrom == 0) {
        this.agerangefromeerr = "Please enter valid age";
        this.errStatus = true;
      }
      if (this.errStatus == false) {
        this.agerangefromeerr = "";
        return true;
      }
    },
    validAgeFromRange: function (e) {
      var re = /([0-9]){1,2}$/;
      return re.test(e);
    },
    validateAgeRangeToNumber(e) {
      this.errStatus = false;
      if (!this.validAgeToRange(e)) {
        this.agerangetoeerr = "Please enter minimum 1 digit";
        this.errStatus = true;
      }
      if (this.agerangeto <= this.agerangefrom) {
        this.agerangetoeerr = "Please enter valid age range";
        this.errStatus = true;
      }
      if (this.agerangeto == 0) {
        this.agerangetoeerr = "Please enter valid age";
        this.errStatus = true;
      }
      if (this.errStatus == false) {
        this.agerangetoeerr = "";
        return true;
      }

    },
    validAgeToRange: function (e) {
      var re = /([0-9]){1,2}$/;
      return re.test(e);
    },
    validateVoterIdNumber(e) {
      this.errStatus = false;
      if (!this.validVoterId(e)) {
        this.voteriderr = "Please enter valid voter id";
        this.errStatus = true;
      }
      if (this.errStatus == false) {
        this.voteriderr = "";
        return true;
      }
    },

    validVoterId: function (e) {
      var re = /([A-Za-z]){3}([0-9]){7}$/;
      return re.test(e);
    },
    onlysingleagechange($event) {
      this.agerangefrom = '';
      this.agerangeto = '';
      this.agerangefromeerr = '';
      this.agerangetoeerr = '';
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }
    },
    agerangechange($event) {
      this.agerange = '';
      this.agerangeeerr = '';
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }
    },
    // Edit Voter form function start here
    voterDetailEditModalOpen(e) {
      this.editvoterdetailstatus = true;
      this.voterEditStepFirstStatus = true;
      this.voterEditStepTwoStatus = false;
      this.voterEditStepThrirdStatus = false;
      this.showloadervotermodal = true;
      this.getclientdesignations();
      this.getuserdesignation();
            this.isCompanyNameExist = false;
      this.isDesignationExist = false;
      this.edit.primobile = '';
      this.edit.firstname = '';
      this.edit.middlename = '';
      this.edit.lastname = '';
      this.edit.email = '';
      this.edit.gender = '';
      this.edit.birthdate = null;
      this.edit.loksabha = '';
      this.edit.vidhansabha = '';
      this.edit.companyname = '';
      this.edit.designation = '';
      this.edit.importprofilefile = '';
      this.edit.editRowId = '';
      this.edit.fulladdress = '';
      this.edit.pincode = '';
      this.edit.landmark = '';
      this.edit.city = '';
      this.edit.area = '';
      this.edit.bloodgroup = '';
      this.edit.profession = '';
      this.edit.tagname = null;
      this.exitpreviewurl = null;
      this.voterexitid = '';
      this.addharexitid = '';
      this.voterexitstatus = '';
      this.getcities();
      this.deletevotermessage = '';
      this.edit.editRowId = e.ak1;
      if(this.allowshowmobilekaryakartasfk == 1) {
        this.edit.primobile = e.ak24;
      } else if(this.allowshowmobilekaryakartasfk == 0) {
        this.edit.primobile = this.maskedNumber(e.ak24);
      }
      this.voterexitid = e.ak35;
      this.addharexitid = e.ak36;
      this.voterexitstatus = e.is_exist;
      if (e.ak11 != null && e.ak11 != "") {
        this.edit.title = {
          label: e.ak11,
          value: e.ak10,
        };
      }
      if (e.ak29 != 1) {
        this.checkAddUserAuth();
      } else {
        this.checkAddVoterAuth();
      }
      this.edit.firstname = e.ak7;
      this.edit.middlename = e.ak8;
      this.edit.lastname = e.ak9;
      if(this.allowshowemailkaryakartasfk == 1) {
        this.edit.email = e.ak23;
      } else if(this.allowshowemailkaryakartasfk == 0) {
        if(e.ak23 != null && e.ak23 != "") {
          this.edit.email = this.maskedEmail(e.ak23);
        } else {
          this.edit.email = '';
        }
      }
      if (e.ak18 != null && e.ak18 != "") {
        this.edit.birthdate = new Date(e.ak18);
      }
      if (e.ak22 == 1) {
        this.edit.gender = {
          label: "Male",
          value: e.ak22,
        }
      } else if (e.ak22 == 2) {
        this.edit.gender = {
          label: "Female",
          value: e.ak22,
        }
      } else if (e.ak22 == 3) {
        this.edit.gender = {
          label: "Other",
          value: e.ak22,
        }
      }
      if (e.ak63 != null && e.ak63 != "") {
        this.edit.loksabha = {
          label: e.ak63,
          value: e.ak62,
        };
      }
      if (e.ak61 != null && e.ak61 != "") {
        this.edit.vidhansabha = {
          label: e.ak61,
          value: e.ak60,
        };
      }
      if (e.ak84 != null && e.ak84 != "") {
        this.edit.designation = {
          label: e.ak84,
          value: e.ak83,
        };
      }
      if (e.ak90 != null && e.ak90 != "") {
        this.edit.importprofilefile = "https://storage.googleapis.com/" + this.voterstorageimgpath + "/voterphoto/" + e.ak90;
      } else {
        this.edit.importprofilefile = null;
      }
      if (e.ak88 != null && e.ak88 != '') {
        this.edit.companyname = {
          label: e.ak88,
          value: e.ak145,
        };
      }
      this.edituser.votertype = e.ak29;
      if (e.ak110 != null && e.ak110 != '') {
        this.edituser.userdesignation = {
          label: e.ak111,
          value: e.ak110,
        };
      }
      if (e.ak101 != null && e.ak101 != '') {
        this.edituser.teamleadname = {
          label: e.ak103,
          value: e.ak101,
        };
      }
      this.edituser.userrole = e.ak39;
      if (e.ak29 == 3 || e.ak29 == 2) {
        if (e.ak39 != null && e.ak39 != '') {
          this.edituser.roletype = 1;
        } else {
          this.edituser.roletype = 2;
        }
      } else {
        this.edituser.roletype = '';
      }
      if (e.ak41 != null) {
        let arrConverted = e.ak41.split(",");
        this.edituser.selectedTreeValue = arrConverted;
        for (let index = 0; index < arrConverted.length; index++) {
          let idxx = arrConverted[index];
          this.edituser.selectedTreeValue = {
            ...this.edituser.selectedTreeValue,
            [idxx]: { checked: true, partialChecked: false },
          };
        }
      }
      this.edit.fulladdress = e.ak51;
      this.edit.pincode = e.ak58;
      this.edit.landmark = e.ak59;
      if (e.ak55 != null && e.ak55 != "") {
        this.edit.city = {
          label: e.ak55,
          value: e.ak54,
        };
      }
      if (e.ak21 != null && e.ak21 != '') {
        this.edit.bloodgroup = {
          label: e.ak21,
          value: e.ak20,
        };
      }
      if (e.ak122 != null && e.ak122 != '') {
        if (e.ak122 != null && e.ak122 != '') {
        var labels = e.ak122.split(',');
        var values = e.ak121.split(',');
        var tagnames = [];
        for (var i = 0; i < labels.length; i++) {
          var tags = [];
          tags['label'] = labels[i];
          tags['value'] = values[i];
          Object.assign({}, tags);
          var tagobject = Object.assign({}, tags);
          tagnames.push(tagobject);
        }
        this.edit.tagname = tagnames;
      }
      }
      if (e.ak66 != null && e.ak66 != '') {
        this.edit.profession = {
          label: e.ak67,
          value: e.ak66,
        };
      }
      if (e.ak53 != null && e.ak53 != "") {
        this.edit.area = {
          label: e.ak53,
          value: e.ak52,
        };
      }
      if (e.ak105 == 1) {
        this.allow.usermobpermission = true;
    } else {
        this.allow.usermobpermission = false;
    }

    if (e.ak106 == 1) {
        this.allow.useremailpermission = true;
    } else {
        this.allow.useremailpermission = false;
    }

    if (e.ak107 == 1) {
        this.allow.useraadharpermission = true;
    } else {
        this.allow.useraadharpermission = false;
    }

    if (e.ak108 == 1) {
        this.allow.usermobapppermission = true;
    } else {
        this.allow.usermobapppermission = false;
    }
    if (e.ak109 == 1) {
        this.allow.usereditpermission = true;
    } else {
        this.allow.usereditpermission = false;
    }
    if (e.ak165 == 1) {
        this.allow.displaydeletevoterpermission = true;
    } else {
        this.allow.displaydeletevoterpermission = false;
    }
    if (e.ak196 == 1) {
        this.allow.displayaddvoterfamilypermission = true;
    } else {
        this.allow.displayaddvoterfamilypermission = false;
    }
    if (e.ak199 == 1) {
        this.allow.displayaddvoterprofessionalinfopermission = true;
    } else {
        this.allow.displayaddvoterprofessionalinfopermission = false;
    }
    if (e.ak200 == 1) {
        this.allow.displaymodifyvoterprofessionalinfopermission = true;
    } else {
        this.allow.displaymodifyvoterprofessionalinfopermission = false;
    }
    if (e.ak201 == 1) {
        this.allow.displaymanagemodifyfamilyrelationpermission = true;
    } else {
        this.allow.displaymanagemodifyfamilyrelationpermission = false;
    }
    if (e.ak202 == 1) {
        this.allow.displayremovememberfromfamilygrouppermission = true;
    } else {
        this.allow.displayremovememberfromfamilygrouppermission = false;
    }
    if (e.ak208 == 1) {
        this.allow.displaymarkvoteruservippermission = true;
    } else {
        this.allow.displaymarkvoteruservippermission = false;
    }
    if (e.ak203 == 1) {
        this.allow.displaymakeoutgoingcallvoterpermission = true;
    } else {
        this.allow.displaymakeoutgoingcallvoterpermission = false;
    }
    },
    voterDetailEditModalClose() {
      this.editvoterdetailstatus = false;
      setTimeout(() => {
        this.v$.$reset();
      }, 0);
      this.expandedKeys = {};
    },
    updateVoterDetailbtn() {
      this.submitted = true;
      this.errormsg = '';
      this.v$.edit.$validate();
      var formData = new FormData();
      formData.append("ak1", this.edit.editRowId);
      formData.append("ak24", this.edit.primobile);
      formData.append("ak10", this.edit.title.value);
      formData.append("ak11", this.edit.title.label);
      formData.append("ak7", this.edit.firstname);
      if (this.edit.middlename) {
        formData.append("ak8", this.edit.middlename);
      }
      formData.append("ak9", this.edit.lastname);
      var fullname = this.edit.title.label + ' ' + this.edit.firstname + ' ' + this.edit.middlename + ' ' + this.edit.lastname;
      formData.append('ak5', fullname);
      if (this.edit.email) {
        formData.append("ak23", this.edit.email);
      }
      formData.append('ak18', moment(this.edit.birthdate).format("YYYY-MM-DD"));
      formData.append("ak22", this.edit.gender.value);
      formData.append("ak62", this.edit.loksabha.value);
      formData.append("ak63", this.edit.loksabha.label);
      formData.append("ak60", this.edit.vidhansabha.value);
      formData.append("ak61", this.edit.vidhansabha.label);
      formData.append('ak51', this.edit.fulladdress);
      formData.append('ak58', this.edit.pincode);
      if (this.edit.landmark) {
        formData.append("ak59", this.edit.landmark);
      }
      if (this.edit.tagname) {
        formData.append('tagname', JSON.stringify(this.edit.tagname));
      }
      if (this.edit.bloodgroup) {
        formData.append("ak20", this.edit.bloodgroup.value);
        formData.append("ak21", this.edit.bloodgroup.label);
      }
      if (this.edit.profession) {
        formData.append("ak66", this.edit.profession.value);
        formData.append("ak67", this.edit.profession.label);
      }
      if (this.edit.city) {
        formData.append("ak54", this.edit.city.value);
        formData.append("ak55", this.edit.city.label);
      }
      if (this.edit.area) {
        formData.append("ak52", this.edit.area.value);
        formData.append("ak53", this.edit.area.label);
      }
      if (this.edit.companyname) {
                formData.append("ak145", this.edit.companyname.value);
                formData.append("ak88", this.edit.companyname.label);
            }
      if (this.edit.designation) {
        formData.append("ak83", this.edit.designation.value);
        formData.append("ak84", this.edit.designation.label);
      }
      if (this.edit.voterprofileimg) {
        formData.append("voterprofileimg", this.edit.voterprofileimg);
      }
      if (!this.v$.edit.$error && !this.errorprofileimage) {
        this.addvoterloader = true;
        this.ApiService.updatevotersdetails(formData).then((data) => {
          if (data.status == 200) {
            this.addvoterloader = false;
            var successMsg = data.message;
            this.$toast.open({
              message: successMsg,
              type: "success",
              duration: 3000,
              position: "top-right",
            });
            setTimeout(() => {
              this.v$.$reset();
            }, 0);
            this.getkaryakartalist();
            this.voterEditStepFirstStatus = false;
            this.voterEditStepTwoStatus = true;
          } else {
            this.voterEditStepFirstStatus = true;
            this.addvoterloader = false;
            var errorMsg = data.message;
            this.$toast.open({
              message: errorMsg,
              type: "error",
              duration: 3000,
              position: "top-right",
            });
          }
        });
      } else {
        this.$error;
      }
    },
    updateUserPermissionbtn() {
      this.v$.edituser.$validate();
      let fields = {};
      fields["ak1"] = this.edit.editRowId;
      fields["ak29"] = this.edituser.votertype;
      fields["ak24"] = this.edit.primobile;
      fields["ak7"] = this.edit.firstname;
      if (this.edituser.votertype == 2 || this.edituser.votertype == 3) {
        if (this.edituser.roletype) {
          fields["role_type"] = this.edituser.roletype;
        }
        if (this.edituser.userdesignation.value) {
          fields["ak110"] = this.edituser.userdesignation.value;
        }
        if (this.edituser.userdesignation.label) {
          fields["ak111"] = this.edituser.userdesignation.label;
        }
        if (this.edituser.teamleadname.value) {
          fields["ak101"] = this.edituser.teamleadname.value;
        }
        if (this.edituser.teamleadname.label) {
          fields["ak103"] = this.edituser.teamleadname.label;
        }
        if (this.edituser.userrole) {
          fields["ak39"] = this.edituser.userrole;
        }
        if (this.edituser.selectedTreeValue) {
          fields["modules"] = this.edituser.selectedTreeValue
        }
      }
      if (!this.v$.edituser.$error && !this.exitvotererrormsg) {
        this.editvoterloader = true;
        this.ApiService.updateclientuserpermissions(fields).then((items) => {
          if (items.status == 200) {
            this.editvoterloader = false;
            this.$toast.open({
              message: items.message,
              type: "success",
              duration: 3000,
              position: "top-right",
            });
            this.voterEditStepTwoStatus = false;
            this.voterEditStepThrirdStatus = true;
            if (this.edituser.votertype == 1) {
              this.voterEditStepTwoStatus = false;
              this.editvoterdetailstatus = false;
              this.getkaryakartalist();
            } else if (this.edituser.votertype == 2 || this.edituser.votertype == 3) {
              this.voterEditStepTwoStatus = false;
              this.voterEditStepThrirdStatus = true;
            }
          } else {
            this.$toast.open({
              message: items.message,
              type: "error",
              duration: 3000,
              position: "top-right",
            });
            this.editvoterloader = false;
            this.voterEditStepTwoStatus = true;
            this.usercreateerrormsg = items.msg;
          }
        });
      }
    },
    updateClientUserPermission() {
      let fields = {};
      fields["ak1"] = this.edit.editRowId;
      if (this.allow.usermobpermission == true) {
        fields["ak105"] = 1;
      } else if (this.allow.usermobpermission == false) {
        fields["ak105"] = 0;
      }

      if (this.allow.useremailpermission == true) {
        fields["ak106"] = 1;
      } else if (this.allow.useremailpermission == false) {
        fields["ak106"] = 0;
      }

      if (this.allow.useraadharpermission == true) {
        fields["ak107"] = 1;
      } else if (this.allow.useraadharpermission == false) {
        fields["ak107"] = 0;
      }

      if (this.allow.usermobapppermission == true) {
        fields["ak108"] = 1;
      } else if (this.allow.usermobapppermission == false) {
        fields["ak108"] = 0;
      }
      if (this.allow.usereditpermission == true) {
        fields["ak109"] = 1;
      } else if (this.allow.usereditpermission == false) {
        fields["ak109"] = 0;
      }
      if (this.allow.displaydeletevoterpermission == true) {
        fields["ak165"] = 1;
      } else if (this.allow.displaydeletevoterpermission == false) {
        fields["ak165"] = 0;
      }
      if (this.allow.displayaddvoterfamilypermission == true) {
        fields["ak196"] = 1;
    } else if (this.allow.displayaddvoterfamilypermission == false) {
        fields["ak196"] = 0;
    }
    if (this.allow.displayaddvoterprofessionalinfopermission == true) {
        fields["ak199"] = 1;
    } else if (this.allow.displayaddvoterprofessionalinfopermission == false) {
        fields["ak199"] = 0;
    }
    if (this.allow.displaymodifyvoterprofessionalinfopermission == true) {
        fields["ak200"] = 1;
    } else if (this.allow.displaymodifyvoterprofessionalinfopermission == false) {
        fields["ak200"] = 0;
    }
    if (this.allow.displaymanagemodifyfamilyrelationpermission == true) {
        fields["ak201"] = 1;
    } else if (this.allow.displaymanagemodifyfamilyrelationpermission == false) {
        fields["ak201"] = 0;
    }
    if (this.allow.displayremovememberfromfamilygrouppermission == true) {
        fields["ak202"] = 1;
    } else if (this.allow.displayremovememberfromfamilygrouppermission == false) {
        fields["ak202"] = 0;
    }
    if (this.allow.displaymarkvoteruservippermission == true) {
        fields["ak208"] = 1;
    } else if (this.allow.displaymarkvoteruservippermission == false) {
        fields["ak208"] = 0;
    }
    if (this.allow.displaymakeoutgoingcallvoterpermission == true) {
        fields["ak203"] = 1;
    } else if (this.allow.displaymakeoutgoingcallvoterpermission == false) {
        fields["ak203"] = 0;
    }
      this.editpermissionloader = true;
      this.ApiService.updateuserallowpermissions(fields).then((items) => {
        if (items.status == 200) {
          this.editpermissionloader = false;
          this.$toast.open({
            message: items.message,
            type: "success",
            duration: 3000,
            position: "top-right",
          });
          this.voterEditStepThrirdStatus = false;
          this.editvoterdetailstatus = false;
          this.getkaryakartalist();
        } else {
          this.editpermissionloader = false;
          this.$toast.open({
            message: items.message,
            type: "error",
            duration: 3000,
            position: "top-right",
          });
        }
      });
    },
    handleUploadVoterProfile() {
      var file = this.$refs.voterprofileimg.files[0];
      let allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
      if (!file.size > 2048) {
        this.errorprofileimage = 'File size cannot exceed more than 2MB';
      } else if (!file.name.match(allowedExtensions)) {
        this.errorprofileimage = 'Invalid file type';
      } else {
        this.errorprofileimage = '';
        this.edit.voterprofileimg = this.$refs.voterprofileimg.files[0];
        this.exitpreviewurl = this.edit.voterprofileimg;
      }
    },
    createVoterEditDesignation(event) {
      if (event && event.value) {
        if (Number.isInteger(event.value)) {
          this.edit.designation = { value: event.value, label: event.label };
        } else {
          this.edit.designation = { value: 0, label: event.label };
        }
      }
    },
    checkAddUserAuth() {
      if ((this.voterexitid == '' || this.voterexitid == null) && (this.addharexitid == '' || this.addharexitid == null)) {
        this.exitvotererrormsg = true;
      } else {
        this.exitvotererrormsg = false;
      }
    },
    checkAddVoterAuth() {
      this.exitvotererrormsg = false;
    },
    // Edit Voter form function end here
    getalltitle(e) {
      this.ApiService.getalltitle(e).then((data) => {
        if (data.status == 200) {
          this.titleList = data.data;
        }
      });
    },

    getloksabha(e) {
      this.edit.vidhansabha = '';
      this.ApiService.getloksabha(e).then((data) => {
        if (data.status == 200) {
          this.loksabhaList = data.data;
        }
      });
    },
    getvidhansabhaName(e) {
      this.vidhanloaderflag = true;
      this.edit.vidhansabha = '';
      this.ApiService.getvidhansabha({ lokhsabha_id: e.value }).then((items) => {
        if (items.success === true) {
          this.vidhansabhaList = items.data;
          this.vidhanloaderflag = false;
        } else {
          this.vidhanloaderflag = false;
          this.vidhansabhaList = [];
        }
      });
    },
    getclientdesignations(e) {
      this.ApiService.getclientdesignations(e).then((data) => {
        if (data.status == 200) {
          this.clientdesignationList = data.data;
        }
      });
    },
    getuserdesignation(e) {
      this.ApiService.getuserdesignations(e).then((data) => {
        if (data.status == 200) {
          this.userdesignationList = data.data;
        }
      });
    },
    getactiveusers(e) {
      this.ApiService.getactiveusers(e).then((data) => {
        if (data.status == 200) {
          this.teamleadList = data.data;
        }else{
          this.teamleadList = null;
        }
      });
    },
    getusersexcludingloginuser(e) {
      this.ApiService.getusersexcludingloginuser(e).then((data) => {
        if (data.status == 200) {
          this.userList = data.data;
          for (let i = 0; i < this.userList.length; i++) {
            let designation = '';
            if (this.userList[i]['ak84'] != null && this.userList[i]['ak84'] != '') {
              designation = ' (' + this.userList[i]['ak84'] + ')';
            }
            this.userList[i]['label'] = this.userList[i]['label'] + '' + designation;
          }
          this.assignuserList = this.userList;
          this.showloaderreassignmodal = false;
        }
      });
    },
    isLetterWithOutSpace(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z]+$/.test(char)) return true;
      else e.preventDefault();
    },
    handleEditGender() {
      if (this.edit.title.value == 1) {
        this.edit.gender = {
          label: "Male",
          value: 1,
        }
      } else if (this.edit.title.value == 2 || this.edit.title.value == 3) {
        this.edit.gender = {
          label: "Female",
          value: 2,
        }
      } else {
        this.edit.gender = '';
      }
    },
    expandAll() {
for (let node in this.nodes) {
        this.expandNode(this.nodes[node]);
      }
      this.expandedKeys = { ...this.expandedKeys };
    },
    collapseAll() {
      this.expandedKeys = {};
    },

    expandNode(node) {
      if (node.children && node.children.length) {
        this.expandedKeys[node.key] = true;
        for (let child of node.children) {
          this.expandNode(child);
        }
      }
    },
    // whatsApp modal function start here
    whatsappHistoryModalOpen(e) {
      this.whatsApprowId = e.ak1;
      this.whatsappname = e.ak5;
      this.whatsappnumber = e.ak24;
      this.whatsappvoterprofile = e.ak90;
      this.whatsappaadharprofile = e.ak33;
      this.disabledWhatsAppInputPermission = e.whatsapp;
      this.whatsappmodalsflag = true;
      this.getwhatsappchatlogs();
    },
    getwhatsappchatlogs() {
      this.showloadermodal = true;
      this.chatInput = "";
      this.file1 = "";
      this.uploadfilename = "";
      this.ApiService.getwhatsappchatlogs({ lr13: this.whatsApprowId }).then((data) => {
        if (data.success == true) {
          this.showloadermodal = false;
          this.whatsAppChatHistory = data.records;
        }
      });
      this.scrollmessage();
    },
    whatsappHistoryModalClose() {
      this.whatsappmodalsflag = false;
      clearInterval(this.timer3);
            this.ApiLoadingSwitch = false;
    },
    switchLoadingStatus() {
        if (this.ApiLoadingSwitch == true) {
            this.timer3 = window.setInterval(() => {
                this.getwhatsappchatlogstimer();
            }, 2000);
        } else {
            clearInterval(this.timer3);
        }
    },
    getwhatsappchatlogstimer() {
            this.ApiService.getwhatsappchatlogs({ lr13: this.rowId }).then((data) => {
                if (data.success == true) {
                    this.whatsAppChatHistory = data.records;
                }
            });
        },
    sendMessage() {
      const formData = new FormData();
      formData.append("mobile_number", this.whatsappnumber);
      formData.append("voter_fk", this.whatsApprowId);
      formData.append("voter_fk_value", this.whatsappname);
      if (this.chatInput) {
        formData.append("message", this.chatInput);
      }
      if (this.file1) {
        formData.append("media", this.file1);
      }
      if(this.disabledWhatsAppInputPermission == 0) {
        formData.append("session_message", 0);
      } else {
        formData.append("session_message", 1);
      }
      this.showloaderbtn = true;
      this.ApiService.sendwhatsappmessage(formData).then((data) => {
        if (data.success == true) {
          this.showloaderbtn = false;
          this.chatInput = "";
          this.file1 = "";
          this.uploadfilename = "";
          this.getwhatsappchatlogs();
          this.scrollmessage();
        } else {
          this.showloaderbtn = false;
          this.chatInput = "";
          this.file1 = "";
          this.uploadfilename = "";
          this.getwhatsappchatlogs();
        }
      }
      );
    },
    validateAttachment(e) {
      var fileData = e.target.files[0];
      this.uploadfilename = fileData.name;
      if (this.$refs.uploadfile.files.length > 3) {
        this.$toast.open({
          message: "Maximum 3 files are allowed",
          type: "success",
          duration: 3000,
          position: "top-right",
        });
      } else {
        let len = this.$refs.uploadfile.files.length;
        if (len === 1) {
          this.file1 = this.$refs.uploadfile.files[0];
        } else if (len === 2) {
          this.file1 = this.$refs.uploadfile.files[0];
          this.file2 = this.$refs.uploadfile.files[1];
        } else if (len === 3) {
          this.file1 = this.$refs.uploadfile.files[0];
          this.file2 = this.$refs.uploadfile.files[1];
          this.file3 = this.$refs.uploadfile.files[2];
        }
      }
    },
    scrollmessage() {
      setTimeout(() => {
        var messageBody = document.querySelector('#chatcontainer');
        messageBody.scrollTop = messageBody.scrollHeight - messageBody.clientHeight;
      }, 500);
    },
    whatsappimagepreview(data) {
      this.previewimg = data.lr20;
      this.ImagePreviewDialog = true;
    },
    whatsappimagepreviewClose() {
      this.ImagePreviewDialog = false;
    },
    // WhatsApp Modal function end here
    createVoterAddCompanyName(event) {
      if (event && event.value) {
        if (Number.isInteger(event.value)) {
          this.edit.companyname = { value: event.value, label: event.label };
        } else {
          this.edit.companyname = { value: 0, label: event.label };
        }
      }
    },
    // Voter Details View Modal Common function start here
    voterdetailModalOpen(e) {
      this.rowId = '';
      this.mobile_number = '';
      this.voterNoFK = '';
      this.searchaadharmobile = '';
      this.licencebirthdate = null;
      this.rowId = e.ak1;
      this.familyrelationstatus = e.az6;
      this.mobile_number = e.ak24;
      this.voterNoFK = e.ak35;
      this.searchaadharmobile = e.ak24;
      if (e.ak18 != null && e.ak18 != "") {
        this.licencebirthdate = new Date(e.ak18);
      } else {
        this.licencebirthdate = null;
      }
      this.votercardnumber = "";
      this.aadharcardnumber = "";
      this.aadharotp = "";
      this.pancardnumber = "";
      this.drivingnumber = "";
      this.aadhar_number_fk = '';
      this.hideaadharmobile = false;
      this.AadharTabDetails = "";
      this.voterTabDetails = "";
      this.LicenseTabDetails = "";
      this.PanTabDetails = "";
      this.votergovconfirmdata = [];
      this.voterinputdisplayflag = false;
      this.aadhardivdisplayflag = 0;
      this.errmsg = '';
      this.viewvoterdetailstatus = true;
      this.getvoterdetails();
    },

    voterDetailModalClose() {
      this.viewvoterdetailstatus = false;
    },
    // Voter Details View Modal Common function end here
    // Get Driving Voter details function start here
    getlicensedetails() {
      this.errmsg = '';
      this.showloaderdrivingmodal = true;
      this.ApiService.getlicensedetails({ ak1: this.rowId }).then((data) => {
        if (data.success == true) {
          this.LicenseTabDetails = data.data;
          this.drivingdivdisplayflag = 1;
          this.showloaderdrivingmodal = false;
        } else {
          this.LicenseTabDetails = '';
          this.drivingdivdisplayflag = 0;
          this.showloaderdrivingmodal = false;
        }
      });
    },
    getdrivingdetails() {
      this.errmsg = '';
      this.showloader = true;
      let fields = {};
      fields["ak1"] = this.rowId;
      fields["update"] = 0;
      fields["drivingnumber"] = this.drivingnumber;
      fields["dob"] = moment(this.licencebirthdate).format('YYYY-MM-DD');
      this.ApiService.getdrivinglicensedetails(fields).then((data) => {
        if (data.success == true) {
          this.LicenseTabDetails = data.data;
          this.drivingdivdisplayflag = 1;
          this.showloader = false;
        } else {
          this.errmsg = data.message;
          this.LicenseTabDetails = '';
          this.drivingdivdisplayflag = 0;
          this.showloader = false;
        }
      });
    },
    // Get Driving Voter details function end here
    // Get Pan Card Voter details function start here
    getpandetails() {
      this.errmsg = '';
      this.showloaderpanmodal = true;
      this.ApiService.getpandetails({ ak1: this.rowId }).then((data) => {
        if (data.success == true) {
          this.PanTabDetails = data.data;
          this.pandivdisplayflag = 1;
          this.showloaderpanmodal = false;
        } else {
          this.errmsg = data.message;
          this.pandivdisplayflag = 0;
          this.showloaderpanmodal = false;
        }
      });
    },
    getpancarddetails() {
      this.errmsg = '';
      this.showloader = true;
      this.ApiService.getpancarddetails({ ak1: this.rowId, update: 0, pancardnumber: this.pancardnumber }).then((data) => {
        if (data.status == 200) {
          this.PanTabDetails = data.data;
          this.pandivdisplayflag = 1;
          this.showloader = false;
          this.errmsg = '';
        } else {
          this.errmsg = data.message;
          this.pandivdisplayflag = 0;
          this.showloader = false;
        }
      });
    },
    // Get Pan Card Voter details function end here
    // View voter details tab function start here 
    getvoterdetails() {
      this.voterTabDetails = '';
      this.voterarraylength = '';
      this.showloadervotermodal = true;
      this.ApiService.addvoterdetails({ ak1: this.rowId, update: 0, ak24: this.mobile_number }).then((data) => {
        if (data.success == true) {
          this.voterTabDetails = data.data;
          this.voterarraylength = data.data.length;
          this.voterdivdisplayflag = true;
          this.voterinputdisplayflag = true;
          this.showloadervotermodal = false;
          this.alreadyExitVoterMessage = '';
        } else {
          this.voterinputdisplayflag = true;
          this.voterdivdisplayflag = false;
          this.showloadervotermodal = false;
          this.alreadyExitVoterMessage = '';
        }
      });
    },
    confirmModalOpen(e) {
      this.confirm_tune_id = e.ak1;
      this.voteridToSwitch = e.ak35;
      this.voteridstatusfk = e.ab26
      this.confirm_popup_status = true;
      this.confirm_status_value = '';
    },
    confirmModalClose() {
      this.confirm_popup_status = false;
      this.confirm_tune_id = '';
      this.voteridToSwitch = '';
      this.confirm_status_value = '';
      this.getvoterdetails();
    },
    changePrimaryVoterStatus() {
      let fields = {};
      fields["ak1"] = this.confirm_tune_id;
      fields["ak35"] = this.voteridToSwitch;
      if (this.confirm_status_value == 0 || this.confirm_status_value == false) {
        fields["ab26"] = this.voteridstatusfk;
      }
      if (this.confirm_status_value == 1 || this.confirm_status_value == true) {
        fields["ab26"] = this.voteridstatusfk;
      }
      this.showconfirmprivoterloaderbtn = true;
      this.ApiService.voterCardSetAsPrimary(fields).then((items) => {
        if (items.status === 200) {
          var successMsg = items.message;
          this.$toast.open({
            message: successMsg,
            type: "success",
            duration: 3000,
            position: "top-right",
          });
          this.showconfirmprivoterloaderbtn = false;
          this.getvoterdetails();
          this.confirm_tune_id = '';
          this.voteridToSwitch = '';
          this.confirm_status_value = '';
          this.voteridstatusfk = '';
          this.confirm_popup_status = false;
          setTimeout(() => {
            this.v$.$reset();
          }, 0);
        } else {
          this.showconfirmprivoterloaderbtn = false;
          var errorMsg = items.message;
          this.$toast.open({
            message: errorMsg,
            type: "error",
            duration: 3000,
            position: "top-right",
          });
        }
      });
    },
    confirmUpdateModalOpen(e) {
      this.updatevoterid = e.ak35;
      this.confirm_update_popup_status = true;
    },
    confirmUpdateModalClose() {
      this.confirm_update_popup_status = false;
    },
    fetchvoteriddetails() {
      this.showloader = true;
      let fields = {};
      var result = [];
      fields["ak24"] = this.mobile_number;
      fields["ak1"] = this.rowId;
      fields["voter_id"] = this.votercardnumber;
      fields["update"] = 0;
      this.ApiService.addvoterdetails(fields).then((items) => {
        if (items.success === true) {
          if (items.is_exists == 1) {
            result.push(items.data);
            this.alreadyExitVoterMessage = items.message;
            this.votercardnumber = '';
            this.showloader = false;
            this.voterinputdisplayflag = true;
            this.voterdivdisplayflag = true;
            this.voterTabDetails = result;
            var errorMsg = items.message;
            this.$toast.open({
              message: errorMsg,
              type: "error",
              duration: 3000,
              position: "top-right",
            });
          } else {
            this.showloader = false;
            this.alreadyExitVoterMessage = items.message;
            this.voterTabDetails = items.data;
            this.voterinputdisplayflag = true;
            this.voterdivdisplayflag = false;
            this.voterupdatedisplayflag = true;
            this.votergovconfirmdata = items.data;
            var successMsg = items.message;
            this.$toast.open({
              message: successMsg,
              type: "warning",
              duration: 3000,
              position: "top-right",
            });
          }
        } else {
          var errorMsgs = items.message;
          this.$toast.open({
            message: errorMsgs,
            type: "error",
            duration: 3000,
            position: "top-right",
          });
          this.showloader = false;
        }
      });
    },
    updateVoterPermission() {
      this.showconfirmloaderbtn = true;
      let fields = {};
      fields["ak24"] = this.mobile_number;
      fields["ak1"] = this.rowId;
      fields["voter_id"] = this.updatevoterid;
      fields["update"] = 1;
      this.ApiService.addvoterdetails(fields).then((items) => {
        if (items.success === true) {
          this.updatevoterid = '';
          this.showloader = false;
          this.voterupdatedisplayflag = false;
          this.voterdivdisplayflag = false;
          this.confirm_update_popup_status = false;
          var successMsg = items.message;
          this.$toast.open({
            message: successMsg,
            type: "success",
            duration: 3000,
            position: "top-right",
          });
          this.showconfirmloaderbtn = false;
          // this.alreadyExitVoterMessage = '';
          // this.updateVoterBtnStatus = false;
          this.getvoterdetails();
        } else {
          this.alreadyExitVoterMessage = items.message;
          var errorMsg = items.message;
          this.$toast.open({
            message: errorMsg,
            type: "error",
            duration: 3000,
            position: "top-right",
          });
          this.showconfirmloaderbtn = false;
        }
      });
    },
    // View voter details tab function end here
    // Get Aadhar Detail Form Function start here
    // First Get Aadhar Details API call
    getaadhardetails() {
      this.aadharUpdateBtnDaysMsg = '';
      this.showloaderaadharmodal = true;
      this.ApiService.getaadhardetails({ ak1: this.rowId }).then((data) => {
        if (data.success == true) {
          this.AadharTabDetails = data.data;
          if (this.AadharTabDetails.ak36 != null && this.AadharTabDetails.ak36 != '') {
            this.aadhardivdisplayflag = 1;
            if (this.AadharTabDetails.update == 0) {
              this.aadharupdatebtnflag = false;
              if(this.AadharTabDetails.update_days != 30) {
                  this.aadharUpdateBtnDaysMsg = 'You can update Aadhar Card details after ' + (30 - this.AadharTabDetails.update_days) + ' Days';
              }
            } else {
              this.aadharupdatebtnflag = true;
              this.aadharUpdateBtnDaysMsg = '';
            }
          } else {
            this.aadhardivdisplayflag = 0;
          }
          this.showloaderaadharmodal = false;
        } else {
          this.showloaderaadharmodal = false;
        }
      });
    },
    confirmAadharUpdateModalOpen(aadhar) {
      this.confirm_aadhar_update_popup_status = true;
      this.aadhar_number_fk = aadhar;
    },
    confirmAadharUpdateModalClose() {
      this.confirm_aadhar_update_popup_status = false;
    },
    updateAadharReloadPermission() {
      this.showaconfirmloaderbtn = true;
      this.confirm_aadhar_update_popup_status = false;
      let fields = {};
      fields["ak24"] = this.mobile_number;
      fields["aadhar_number"] = this.aadhar_number_fk;
      fields["ak1"] = this.rowId;
      fields["update"] = 1;
      this.showloaderaadharmodal = true;
      this.ApiService.generateaadharotp(fields).then((items) => {
        if (items.success === true) {
          this.aadharcardnumber = this.aadhar_number_fk;
          this.aadhardivdisplayflag = 0;
          this.showupdateloader = false;
          this.showaconfirmloaderbtn = false;
          var successMsg = items.message;
          this.$toast.open({
            message: successMsg,
            type: "success",
            duration: 3000,
            position: "top-right",
          });
          this.displayMobilePopUp = true;
          this.startMobileTimer();
          this.timerMobileStatus = true;
          this.aadharotp = "";
          this.mobileotperr = '';
          this.showloader = false;
          this.sendOTPMessage = items.message;
          this.mobile_otp_status = 0;
          this.showresentloader = false;
          this.resentotpbtnstatus = false;
          this.showloaderaadharmodal = false;
        } else {
          this.resentotpbtnstatus = false;
          var errorMsg = items.message;
          this.$toast.open({
            message: errorMsg,
            type: "error",
            duration: 3000,
            position: "top-right",
          });
          this.showresentloader = false;
          this.sendOTPMessage = items.message;
          this.showaconfirmloaderbtn = false;
          this.showloaderaadharmodal = false;
          this.systemlimiterrormsg = items.message;
        }
      });
    },
    sendAadharOtp() {
      this.showloader = true;
      if (
        this.aadharcardnumber != "" &&
        this.aadharcardnumber != null &&
        this.aadharcardnumber.length == 12
      ) {
        let fields = {};
        fields["ak24"] = this.mobile_number;
        fields["aadhar_number"] = this.aadharcardnumber;
        fields["ak1"] = this.rowId;
        fields["update"] = 0;
        this.ApiService.generateaadharotp(fields).then((items) => {
          if (items.success === true) {
            // is_exits == 1 message display 
            // is_exits == 0 OTP Send
            if (items.data.is_exits == 1) {
              this.alreadyExitMessage = items.message;
              this.showloader = false;
              this.aadharcardnumber = '';
              this.displayMobilePopUp = false;
              var errorMsg = items.message;
              this.$toast.open({
                message: errorMsg,
                type: "error",
                duration: 3000,
                position: "top-right",
              });
            } else {
              this.alreadyExitMessage = '';
              this.displayMobilePopUp = true;
              this.startMobileTimer();
              this.timerMobileStatus = true;
              this.aadharotp = "";
              this.showloader = false;
              this.sendOTPMessage = items.message;
              var successMsg = items.message;
              this.$toast.open({
                message: successMsg,
                type: "success",
                duration: 3000,
                position: "top-right",
              });
            }
          } else {
            this.alreadyExitMessage = '';
            var errorMsgs = items.message;
            this.$toast.open({
              message: errorMsgs,
              type: "error",
              duration: 3000,
              position: "top-right",
            });
            this.showloader = false;
            this.aadharcardnumber = '';
            this.aadharotp = "";
            this.timerMobileStatus = false;
            this.systemlimiterrormsg = items.message;
          }
        });
      } else {
        this.$toast.open({
          message: "Please enter valid Aadhar No.",
          type: "warning",
          duration: 3000,
          position: "top-right",
        });
      }
    },
    resendAadharOtp() {
      this.showresentloader = true;
      let fields = {};
      fields["ak24"] = this.mobile_number;
      fields["aadhar_number"] = this.aadharcardnumber;
      fields["ak1"] = this.rowId;
      fields["update"] = 0;
      fields["resend_otp"] = 1;
      this.ApiService.generateaadharotp(fields).then((items) => {
        if (items.success === true) {
          this.showupdateloader = false;
          var successMsg = items.message;
          this.$toast.open({
            message: successMsg,
            type: "success",
            duration: 3000,
            position: "top-right",
          });
          this.displayMobilePopUp = true;
          this.startMobileTimer();
          this.timerMobileStatus = true;
          this.aadharotp = "";
          this.mobileotperr = '';
          this.showloader = false;
          this.sendOTPMessage = items.message;
          this.mobile_otp_status = 0;
          this.showresentloader = false;
          this.resentotpbtnstatus = false;
        } else {
          this.resentotpbtnstatus = false;
          var errorMsg = items.message;
          this.$toast.open({
            message: errorMsg,
            type: "error",
            duration: 3000,
            position: "top-right",
          });
          this.showresentloader = false;
          this.sendOTPMessage = items.message;
          this.systemlimiterrormsg = items.message;
        }
      });
    },
    verifyAadharOtp() {
      this.showloader = true;
      this.mobile_otp_status = 0;
      let fields = {};
      fields["ak24"] = this.mobile_number;
      fields["ak1"] = this.rowId;
      fields["otp"] = this.aadharotp;
      fields["aadhar_number"] = this.aadharcardnumber;
      fields["update"] = 1;
      this.ApiService.getaadharcarddetails(fields).then((items) => {
        if (items.success === true) {
          this.showloaderaadharmodal = false;
          this.aadhardivdisplayflag = 1;
          this.aadharcardnumber = '';
          this.aadharerr = '';
          this.sendOTPMessage = '';
          this.displayMobilePopUp = false;
          this.aadharotp = '';
          this.timerMobileStatus = false;
          this.timerMobileCount = 120;
          this.alreadyExitMessage = '';
          this.AadharTabDetails = items.data;
          this.showloader = false;
          var successMsg = items.message;
          this.$toast.open({
            message: successMsg,
            type: "success",
            duration: 3000,
            position: "top-right",
          });
          this.aadharupdatebtnflag = false;
        }
        else if (items.status == 500) {
          this.mobile_otp_status = 1;
        } else {
          this.sendOTPMessage = '';
          this.sendOTPMessage = '';
          this.showloader = false;
          this.mobileotperr = items.message;
          var errorMsg = items.message;
          this.$toast.open({
            message: errorMsg,
            type: "error",
            duration: 3000,
            position: "top-right",
          });
          this.timerMobileStatus = false;
          this.timerMobileCount = 0;
          this.aadharotp = "";
          this.showloader = false;
          this.resentotpbtnstatus = true;
          setTimeout(() => {
            this.mobile_otp_status = 1;
          }, 480000);
          // 8 minutes set timeout
        }
      });
    },
    startMobileTimer() {
      this.timerMobileStatus = true;
      if (this.timerMobileCount > 0) {
        setTimeout(() => {
          this.timerMobileCount--;
          this.startMobileTimer();
        }, 1000);
        const minutes = Math.floor(this.timerMobileCount / 60);
        let seconds = this.timerMobileCount % 60;
        if (seconds < 10) {
          this.timerMobileInterval = `0${minutes}:0${seconds}`;
        } else {
          this.timerMobileInterval = `0${minutes}:${seconds}`;
        }
      } else {
        this.timerMobileCount = 120;
        this.timerMobileStatus = false;
        this.resentotpbtnstatus = true;
      }
    },
    // Get Aadhar Detail Form Function end here
    // family hierarchy view detail start here
    voterFamilyRelationList() {
      this.ApiService.voterFamilyRelationList({ ak1: this.familyrelationstatus }).then((data) => {
        if (data.success == true) {
          this.familyTabDetails = data.data;
        } else {
          this.familyTabDetails = '';
        }
      });
    },
    // family hierarchy view detail start here
    validatePanCardNumber(val) {
      this.errStatus = false;
      if (!this.validPAN(val)) {
        this.errpan = "Please enter valid pan number";
        this.errStatus = true;
      }
      if (this.errStatus == false) {
        this.errpan = "";
        return true;
      }
    },

    validPAN: function (e) {
      var re = /([A-Za-z]){5}([0-9]){4}([A-Za-z]){1}$/;
      return re.test(e);
    },

    validateDrivingNumber(e) {
      this.errStatus = false;
      if (!this.validDrivingNo(e)) {
        this.errdriving = "Please enter valid driving licence no";
        this.errStatus = true;
      }
      if (this.errStatus == false) {
        this.errdriving = "";
        return true;
      }
    },

    validDrivingNo: function (e) {
      var re = /([A-Za-z]){2}([0-9]){13}$/;
      return re.test(e);
    },
    checkVoterId() {
      var voterlength = this.votercardnumber.length;
      if (voterlength == 10) {
        var errorflag = 0;
        for (var i = 0, len = this.voterTabDetails.length; i < len; i++) {
          if (this.voterTabDetails[i]['ak35'] === this.votercardnumber.toUpperCase()) {
            errorflag = 1;
            break;
          }
        }
        if (errorflag == 1) {
          this.alreadyExitVoterMessage = 'This Voter Id is already added to your account!';
          this.errStatus = true;
          this.votercardnumber.length == 1;
        } else {
          this.alreadyExitVoterMessage = '';
          this.errStatus = false;
          this.votercardnumber.length == 0;
        }
      } else {
        this.alreadyExitVoterMessage = '';
        this.errStatus = true;
        this.votercardnumber.length == 1;
      }

    },
    checkAadharNumber(){
      if(this.alreadyExitMessage != ''){
        this.alreadyExitMessage = '';
      }else{
        return true;
      }
    },
    getareaList(e) {
      this.ApiService.getareaList(e).then((data) => {
        if (data.status == 200) {
          this.filterareaList = data.data;
        } else {
          this.filterareaList = null;
        }
      });
    },
    onlypincodeEditFormat($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }
      var editpincodeInput = document.getElementById("editpincodeInput");
      if ((editpincodeInput.value == '' || editpincodeInput.length > 1) && (keyCode == 48)) {
        $event.preventDefault();
      }
    },
    onlypincodeFilterFormat($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }
      var filterpincodeInput = document.getElementById("filterpincodeInput");
      if ((filterpincodeInput.value == '' || filterpincodeInput.length > 1) && (keyCode == 48)) {
        $event.preventDefault();
      }
    },
    onlymobileEditFormat($event) {
        let keyCode = $event.keyCode ? $event.keyCode : $event.which;
        if (keyCode < 48 || keyCode > 57) {
            $event.preventDefault();
        }

        var editmobileInput = document.getElementById("editmobileInput");
        if ((editmobileInput.value == '' || editmobileInput.length > 1) && (keyCode == 48 || keyCode == 49 || keyCode == 50 || keyCode == 51 || keyCode == 52)) {
            $event.preventDefault();
        }
    },
    decodeHtml(html){
        return JSON.parse('"' + html + '"');
    },
    CheckAllowDeleteVoterAccess(e) {
        if (e == true) {
          this.ApiService.checkAllowToDelete({ ak1:this.edit.editRowId }).then((data) => {
            if (data.status == 200) {
              this.deletevotermessage = data.message;
              this.allow.displaydeletevoterpermission = 0;
              this.allow.displaydeletevoterpermission = false;
            } else {
              this.deletevotermessage = '';
              this.allow.displaydeletevoterpermission = 1;
              this.allow.displaydeletevoterpermission = true;
            }
          });
        } else if (e == false) {
            this.allow.displaydeletevoterpermission = 0;
            this.allow.displaydeletevoterpermission = false;
        }
    }
  },
};
</script>
<style scoped>
.company-detail-box-outer {
  background: #ffffff 0% 0% no-repeat padding-box;
  padding: 16px 16px 0;
  border: 1px solid #e0e5ed;
  border-radius: 0px 0px 4px 4px;
  width: 100%;
}
.p-highlight .company-detail-box-outer{
  background: rgba(255, 179, 0, 0.12);
}
.customer-label-group {
  margin-bottom: 20px;
}

.customer-label-group .form-label {
  font-family: "AcuminPro-Regular";
  font-size: 13px;
  letter-spacing: 0px;
  color: #4a5463;
  line-height: 13px;
  margin-bottom: 6px;
}

.customer-label-group .from-label-value {
  font-family: "AcuminPro-SemiBold";
  font-size: 14px;
  letter-spacing: 0.28px;
  color: #2b2e38;
  line-height: 20px;
  word-break: break-word;
}

.company-section-outer {
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
  /* height: 514px; */
}

.company-section-outer::-webkit-scrollbar {
  display: block;
}

.company-section-outer::-webkit-scrollbar {
  width: 0.3em;
  height: 0.3em;
}

.company-section-outer::-webkit-scrollbar-thumb {
  background-color: rgb(113 115 119 / 49%);
  border-radius: 3px;
}

.custom-outline-whatsapp-btn {
  background: #e4f8f0 0% 0% no-repeat padding-box;
  border: 1px solid #bbd9cc;
  border-radius: 4px;
  padding: 5px 6px;
  line-height: 10px;
}

.custom-outline-whatsapp-btn .whatsapp-color {
  color: #2ca270;
  font-size: 18px;
}

.custom-outline-call-btn {
  background: #e4f8f0 0% 0% no-repeat padding-box;
  border: 1px solid #bbd9cc;
  border-radius: 4px;
  padding: 7.5px;
  line-height: 10px;
}

.custom-outline-call-btn .call-btn-color {
  color: #2ca270;
  font-size: 13px;
}

.modal-bulk-next-btn.btn {
  background: #5266e5 0% 0% no-repeat padding-box;
  border-color: #5266e5;
  border-radius: 4px;
  letter-spacing: 0.26px;
  color: #ffffff;
  font-size: 13px;
  line-height: 20px;
  padding: 6px 35px;
  font-family: "AcuminPro-Regular";
}

.modal-bulk-next-btn:focus {
  box-shadow: none;
}

/* whatApp style css start here */
.horizontal-line {
  border: 0.5px solid #e9e9e9;
  opacity: 1;
}

.chatbox-inner-content {
  border-bottom: 1px solid #e9e9e9;
  opacity: 1;
}

.chatbox-inner-content:hover {
  /* border: 1px solid var(--unnamed-color-e9e9e9); */
  background: #f7f9fd 0% 0% no-repeat padding-box;
  /* border: 1px solid #E9E9E9; */
  opacity: 1;
}

.chatbox-right-outer {
  max-width: 752px;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  /* height: 550px; */
  padding-left: 0px;
  border-radius: 0.3rem;
  height: 100%;
}

.chatbox-right-outer .chatbox-inner-content {
  /* padding: 20px 0px 0px; */
  opacity: 1;
  height: 100%;
}

.widget-chat {
  position: relative;
  min-height: 400px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.p-dialog-content {
  position: relative;
  background: #efe7dd url("/assets/images/whatsapp-bg-img.jpg") repeat;
  z-index: 0;
  padding: 0;
  overflow: auto;
  height: 100%;
}

.conversation-container {
  height: calc(100% - 5px);
    overflow-x: hidden;
    padding: 0 16px;
    margin-bottom: 5px;
}

.message {
  color: #000;
  clear: both;
  line-height: 18px;
  font-size: 15px;
  padding: 8px;
  position: relative;
  margin: 8px 0;
  max-width: 50%;
  word-wrap: break-word;
}

.message.sent {
  background: #d2f1e1;
  border-radius: 5px 0px 5px 5px;
  float: right;
  text-align: left;
  letter-spacing: 0px;
  color: #4a4a4a;
  font-size: 13px;
  opacity: 1;
  font-family: "AcuminPro-Regular";
}

.message.received {
  background: #cfddf5;
  border-radius: 0px 5px 5px 5px;
  float: left;
  letter-spacing: 0px;
  color: #4a4a4a;
  opacity: 1;
  font-family: "AcuminPro-Regular";
  font-size: 13px;
}

.metadata {
  display: inline-block;
  float: right;
  padding: 0 0 0 7px;
  position: relative;
  bottom: -4px;
}

.whatsapp-header {
  background: #005e54 0% 0% no-repeat padding-box;
}

.whatsapp-header .modal-title {
  color: #ffffff;
}

.whatsapp-header .btn-close {
  background-color: #ffffff;
}

.whatsapp-header-name {
  font-size: 17px;
}

.whatsapp-header-number {
  font-size: 12px;
}

.conversation-container::-webkit-scrollbar,
.p-dialog-content::-webkit-scrollbar,
.input-msg::-webkit-scrollbar {
  transition: all 0.5s !important;
  width: 3px !important;
  height: 3px !important;
  z-index: 10 !important;
}

.conversation-container::-webkit-scrollbar-thumb,
.p-dialog-content::-webkit-scrollbar-thumb,
.input-msg::-webkit-scrollbar-thumb {
  background: #7f7777 !important;
}

.metadata .time {
  color: rgba(0, 0, 0, 0.45);
  font-size: 11px;
  display: inline-block;
}

.message.sent:after {
  border-width: 0px 0 10px 10px;
  border-color: transparent transparent transparent #d2f1e1;
  top: 0;
  right: -10px;
}

.message.received:after {
  border-width: 0px 10px 10px 0;
  border-color: transparent #cfddf5 transparent transparent;
  top: 0;
  left: -10px;
}

.message:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
}

.whatsapp-received-image {
  max-width: 150px;
  width: 100%;
  display: block;
  cursor: pointer;
}

.whatsapp-image-cursor {
  opacity: 1;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.whatsapp-image-cursor:hover {
  opacity: 0.5;
  display: block;
}

.conversation-compose {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  overflow: hidden;
  height: 50px;
  width: 100%;
  z-index: 2;
}

.conversation-compose .input-msg {
  border: 0;
  flex: 1 1 auto;
  font-size: 16px;
  margin: 0;
  outline: none;
  min-width: 50px;
  height: 50px !important;
  overflow: auto !important;
  border-radius: 5px 0 0 5px;
}

.conversation-compose .input-msg,
.conversation-compose .attachment {
  background: #fff;
  height: 100%;
}
.conversation-compose .input-msg:disabled,
.conversation-compose .send:disabled {
    background-color: #e9ecef;
}

.conversation-compose .send-btn-outer {
  background: transparent;
  border: 0;
  flex: 0 0 auto;
  margin-left: 8px;
  padding: 0;
  position: relative;
  outline: none;
}

.send-btn-outer .pi.pi-send {
  font-size: 1.5rem;
  transform: rotate(45deg);
}

.conversation-compose .send {
  background: #008a7c;
  border-radius: 50%;
  color: #fff;
  position: relative;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0px;
  margin-bottom: 2px;
  border: 0;
}

/* .conversation-compose .send:hover {
    border-color: #86b7fe;
} */

.conversation-compose .input-msg:hover {
  background-image: none;
}

.conversation-compose .input-msg:focus {
  background-color: #ffffff;
}

.whatsapp-textbtn-outer {
  padding-left: 8px;
  padding-top: 10px;
  padding-right: 8px;
  background-color: #d3d3d3;
  padding-bottom: 9px;
  border-radius: 0 0 0.3rem 0.3rem;
  width: 100%;
  margin: 0;
}

.conversation-compose .attachment {
  flex: 0 0 auto;
  border-radius: 0 0 5px 0;
  text-align: center;
  position: relative;
}

.conversation-compose .attachment:after {
  border-width: 0px 0 10px 10px;
  border-color: transparent transparent transparent #fff;
  border-style: solid;
  position: absolute;
  width: 0;
  height: 0;
  content: "";
  top: 0;
  right: -10px;
}

.conversation-compose .attachment .p-fileupload.p-fileupload-basic {
  transform: translate(-50%, -50%);
  position: relative;
  top: 50%;
  left: 50%;
}

.conversation-compose .attachment .p-fileupload-basic .p-fileupload-choose {
  display: block;
  background-color: transparent;
}

.conversation-compose .attachment .pi {
  display: block;
  color: #7d8488;
  font-size: 24px;
  margin-right: 0px;
}

.conversation-compose .attachment .p-button-label {
  display: none;
}

.conversation-compose .attachment .p-ink {
  display: none;
}

.form-control.input-msg.custom-chat-group-textarea {
  text-align: left;
  letter-spacing: 0px;
  color: #4a4a4a;
  opacity: 1;
  font-size: 14px;
  font-family: "AcuminPro-Regular";
  padding: 10px 10px;
  resize: none;
}

.form-control.input-msg.custom-chat-group-textarea::placeholder {
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  font-size: 14px;
  font-family: "AcuminPro-Regular";
  text-align: start;
}

.form-control.input-msg.custom-chat-group-textarea:focus {
  border: 1px solid #86b7fe;
  box-shadow: none;
}

.whatsapp-imger-overlay:hover {
  opacity: 0.7;
}

.custom-outline-refresh-btn {
  padding: 6.5px;
  line-height: 10px;
  opacity: .5;
  background-color: #ffffff;
  border-radius: 0.25rem;
}

.custom-outline-refresh-btn:hover {
  opacity: .75;
}

.custom-outline-refresh-btn .refresh-btn-color {
  color: #000000;
  font-size: 13px;
}

@media only screen and (min-width: 1024px) {
  .chatbox-right-outer {
    max-width: 100% !important;
  }
}

/* whatApp style css end here */

.status-primary {
  background: #c8e6c9;
  border-radius: 2px;
  letter-spacing: 0.3px;
  color: #256029;
  opacity: 1;
  font-size: 12px;
  font-family: "AcuminPro-Bold";
  padding: 0.3em 0.2rem 0.2em 0.2rem;
  text-transform: uppercase;
  border: 1px solid #4daa53;
  line-height: 12px;
}

.status-secondary {
  background: #ffd8b2;
  border-radius: 2px;
  letter-spacing: 0.3px;
  color: #805b36;
  opacity: 1;
  font-size: 12px;
  font-family: "AcuminPro-Bold";
  padding: 0.3em 0.2rem 0.2em 0.2rem;
  text-transform: uppercase;
  border: 1px solid #c79b6f;
  line-height: 12px;
}

.status-other {
  background: #ffcdd2;
  border-radius: 2px;
  letter-spacing: 0.3px;
  color: #c63737;
  opacity: 1;
  font-size: 12px;
  font-family: "AcuminPro-Bold";
  padding: 0.3em 0.2rem 0.2em 0.2rem;
  text-transform: uppercase;
  border: 1px solid #d7a5a5;
  line-height: 12px;
}

.user-badge {
  border-radius: 2px;
  padding: 0.3rem 0.3rem 0.1rem 0.3rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 11px;
  letter-spacing: 0.3px;
}
.aadhar-resent-otp-btn {
  letter-spacing: 0px;
  color: #3362db;
  font-size: 14px;
  font-family: "AcuminPro-Regular";
  padding: 0;
  text-decoration: none;
}

.aadhar-resent-otp-btn:focus-visible {
  outline: 0;
}

.aadhar-resent-otp-btn:focus {
  box-shadow: none;
}
.divider-custom-list {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px dashed #d4e4f3;
}
</style>